.clearfix {
  zoom: 1;
}
.clearfix:before,
.clearfix:after {
  content: "";
  display: table;
}
.clearfix:after {
  clear: both;
}
.clear-icon-font:before {
  content: "";
}
/*@import "../Variables";*/
@font-face {
  font-family: "BASFIconFont";
  font-style: normal;
  font-weight: normal;
  src: url("/App_Fonts/BASF_Iconfont/BASF_Iconfont.eot") format("embedded-opentype"), url("/App_Fonts/BASF_Iconfont/BASF_Iconfont.woff") format("woff"), url("/App_Fonts/BASF_Iconfont/BASF_Iconfont.ttf") format("truetype"), url("/App_Fonts/BASF_Iconfont/BASF_Iconfont.svg#BASFIconFont") format("svg");
}
.icon {
  position: relative;
  top: 3px;
  display: inline-block;
  font-size: 1.5rem;
  font-family: BASFIconFont;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon.red {
  color: #C50022;
}
.icon.green {
  color: #65AC1E;
}
.link-with-icon:before {
  position: relative;
  top: 1px;
  float: left;
  display: block;
  font-family: BASFIconFont;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-left: -28px;
}
.link-with-icon {
  font-familly: initial;
}
.contact-icon:before {
  position: relative;
  top: 1px;
  float: left;
  display: block;
  font-family: BASFIconFont;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-right: 10px !important;
}
.contact-icon-width:before {
  width: 15px;
}
.link-icon {
  top: -2px;
}
.icon-width-link {
  width: 1.5rem;
}
.icon.hide {
  display: none;
}
.icon-typ-bigicon:before {
  font-size: 1.75rem;
}
.icon-typ-0:before {
  font-size: 1.5rem;
}
.icon-typ-1:before {
  font-size: 1.25rem;
}
.icon-typ-2:before {
  font-size: 1.125rem;
}
.icon-typ-3:before {
  font-size: .9375rem;
}
.icon-typ-4:before {
  font-size: .625rem;
}
.icon-typ-5:before {
  font-size: 1.5rem;
}
/*@media(max-width: @screen-sm-max) {
    .icon-typ-big:before {
        font-size: 1.75rem;
    }

    .icon-typ-0:before {
        font-size: 1.75rem;
    }

    .icon-typ-1:before {
        font-size: 1.65rem;
    }

    .icon-typ-2:before {
        font-size: 1.375rem;
    }

    .icon-typ-3:before {
        font-size: 1.25rem;
    }

    .icon-typ-4:before {
        font-size: 1.125rem;
    }

    .icon-typ-5:before {
        font-size: .9375rem;
    }

    .icon-typ-6:before {
        font-size: .625rem;
    }
}*/
.icon-back:before {
  content: "\e000";
}
.icon-back-sign:before {
  content: "\e001";
}
.icon-angle-left:before {
  content: "\e000";
}
.icon-angle-left-sign:before {
  content: "\e001";
}
.icon-angle-right:before {
  content: "\e002";
}
.icon-forward:before {
  content: "\e002";
}
.icon-angle-right-sign:before {
  content: "\e003";
}
.icon-forward-sign:before {
  content: "\e003";
}
.icon-angle-down:before,
form .select:after {
  content: "\e004";
}
.icon-angle-down-sign:before {
  content: "\e005";
}
.icon-angle-up:before {
  content: "\e006";
}
.icon-angle-up-sign:before {
  content: "\e007";
}
.icon-minus:before {
  content: "\e008";
}
.icon-minus-sign:before {
  content: "\e009";
}
.icon-plus:before {
  content: "\e00a";
}
.icon-plus-sign:before {
  content: "\e00b";
}
.icon-download:before {
  content: "\e00c";
}
.icon-download-sign:before {
  content: "\e00d";
}
.icon-information:before {
  content: "\e00e";
}
.icon-information-sign:before,
.icon-info:before {
  content: "\e00f";
}
.icon-information-sign:before,
.icon-info-sign:before {
  content: "\e00f";
}
.icon-help:before {
  content: "\e010";
}
.icon-help-sign:before {
  content: "\e011";
}
.icon-close:before,
form input[type="checkbox"]:checked + label.checkbox:before,
form input[type="radio"]:checked + label.checkbox:before,
.mod-mobile-menu button.active .icon:before,
.mod-mobile-menu button.active .language:before,
.mod-mobile-menu .search-wrapper.active button .icon:before,
.mod-mobile-menu .search-wrapper:active button .icon:before {
  content: "\e012";
}
.icon-close-sign:before {
  content: "\e013";
}
.icon-error:before {
  content: "\e014";
}
.icon-error-sign:before {
  content: "\e015";
}
.icon-warning:before,
form .errors:not(:empty):before,
.alert.alert-warning:before {
  content: "\e014";
}
.icon-warning-sign:before {
  content: "\e015";
}
.icon-external-link:before {
  content: "\e016";
}
.icon-external-link-sign:before {
  content: "\e017";
}
.icon-edit:before {
  content: "\e018";
}
.icon-edit-sign:before {
  content: "\e019";
}
.icon-search:before {
  content: "\e01a";
}
.icon-search-sign:before {
  content: "\e01b";
}
.icon-share:before {
  content: "\e01c";
}
.icon-share-sign:before {
  content: "\e01d";
}
.icon-key:before {
  content: "\e01e";
}
.icon-key-sign:before {
  content: "\e01f";
}
.icon-login:before {
  content: "\e020";
}
.icon-login-sign:before {
  content: "\e021";
}
.icon-logged-in:before {
  content: "\e022";
}
.icon-logged-in-sign:before {
  content: "\e023";
}
.icon-mail:before {
  content: "\e024";
}
.icon-mail-sign:before {
  content: "\e025";
}
.icon-phone:before {
  content: "\e026";
}
.icon-phone-sign:before {
  content: "\e027";
}
.icon-print:before {
  content: "\e028";
}
.icon-print-sign:before {
  content: "\e029";
}
.icon-comment:before {
  content: "\e02a";
}
.icon-comment-sign:before {
  content: "\e02b";
}
.icon-trash:before {
  content: "\e02c";
}
.icon-trash-sign:before {
  content: "\e02d";
}
.icon-tiles:before {
  content: "\e02e";
}
.icon-tiles-sign:before {
  content: "\e02f";
}
.icon-reorder:before {
  content: "\e030";
}
.icon-reorder-sign:before {
  content: "\e031";
}
.icon-home:before {
  content: "\e032";
}
.icon-home-sign:before {
  content: "\e033";
}
.icon-calendar:before {
  content: "\e034";
}
.icon-calendar-sign:before {
  content: "\e035";
}
.icon-beaker:before {
  content: "\e036";
}
.icon-beaker-sign:before {
  content: "\e037";
}
.icon-cogs:before {
  content: "\e038";
}
.icon-cogs-sign:before {
  content: "\e039";
}
.icon-world:before {
  content: "\e03a";
}
.icon-world-sign:before {
  content: "\e03b";
}
.icon-star:before,
form .check:before {
  content: "\e03c";
}
.icon-star-sign:before {
  content: "\e03d";
}
.icon-facility:before {
  content: "\e03e";
}
.icon-facility-sign:before {
  content: "\e03f";
}
.icon-molecule:before {
  content: "\e040";
}
.icon-molecule-sign:before {
  content: "\e041";
}
.icon-copyright:before {
  content: "\e042";
}
.icon-copyright-sign:before {
  content: "\e043";
}
.icon-signal:before {
  content: "\e044";
}
.icon-signal-sign:before {
  content: "\e045";
}
.icon-target:before {
  content: "\e046";
}
.icon-target-sign:before {
  content: "\e047";
}
.icon-volume:before {
  content: "\e048";
}
.icon-volume-sign:before {
  content: "\e049";
}
.icon-mute:before {
  content: "\e04a";
}
.icon-mute-sign:before {
  content: "\e04b";
}
.icon-movie:before {
  content: "\e04c";
}
.icon-movie-sign:before {
  content: "\e04d";
}
.icon-circuit:before {
  content: "\e04e";
}
.icon-circuit-sign:before {
  content: "\e04f";
}
.icon-cart:before {
  content: "\e050";
}
.icon-cart-sign:before {
  content: "\e051";
}
.icon-chart-bars:before {
  content: "\e052";
}
.icon-chart-bars-sign:before {
  content: "\e053";
}
.icon-chart-lines:before {
  content: "\e054";
}
.icon-chart-lines-sign:before {
  content: "\e055";
}
.icon-cam:before {
  content: "\e056";
}
.icon-cam-sign:before {
  content: "\e057";
}
.icon-question:before {
  content: "\e058";
}
.icon-question-sign:before {
  content: "\e059";
}
.icon-office:before {
  content: "\e05a";
}
.icon-office-sign:before {
  content: "\e05b";
}
.icon-hierarchy:before {
  content: "\e05c";
}
.icon-hierarchy-sign:before {
  content: "\e05d";
}
.icon-table:before {
  content: "\e05e";
}
.icon-table-sign:before {
  content: "\e05f";
}
.icon-clipboard:before {
  content: "\e060";
}
.icon-clipboard-sign:before {
  content: "\e061";
}
.icon-trend-descending:before {
  content: "\e062";
}
.icon-trend-descending-sign:before {
  content: "\e063";
}
.icon-trend-rising:before {
  content: "\e064";
}
.icon-trend-rising-sign:before {
  content: "\e065";
}
.icon-trend-continuing:before {
  content: "\e066";
}
.icon-trend-continuing-sign:before {
  content: "\e067";
}
.icon-pressrelease:before {
  content: "\e06a";
}
.icon-pressrelease-sign:before {
  content: "\e06b";
}
.icon-preview:before {
  content: "\e06c";
}
.icon-contact-person:before {
  content: "\e06e";
}
.icon-list-view:before {
  content: "\e030";
}
.icon-download-list:before {
  content: "\e070";
}
.icon-fax:before {
  content: "\e076";
}
.icon-language:before {
  content: "\e07c";
}
.icon-language-sign:before {
  content: "\e07d";
}
.icon-mobile:before {
  content: "\e07e";
}
.icon-presentation:before {
  content: "\e08c";
}
.icon-presentation-sign:before {
  content: "\e08d";
}
.icon-ok-validation:before {
  content: "\e082";
}
.icon-ok-validation-sign:before {
  content: "\e083";
}
.icon-expand:before {
  content: "\e084";
}
.icon-expand-sign:before {
  content: "\e085";
}
.icon-location:before {
  content: "\e086";
}
.icon-location-sign:before {
  content: "\e087";
}
.icon-add-download-list:before {
  content: "\e072";
}
.icon-add-download-list-sign:before {
  content: "\e073";
}
#DivMain #DivMainCenterWrapper #ActivityLog {
  height: auto;
}
#DivMain #DivMainCenterWrapper #ActivityLog .content-row {
  zoom: 1;
}
#DivMain #DivMainCenterWrapper #ActivityLog .content-row:before,
#DivMain #DivMainCenterWrapper #ActivityLog .content-row:after {
  content: "";
  display: table;
}
#DivMain #DivMainCenterWrapper #ActivityLog .content-row:after {
  clear: both;
}
#DivMain #DivMainCenterWrapper #ActivityLog .content-row .content-cell {
  float: left;
}
#DivMain #DivMainCenterWrapper #ActivityLog .content-row .content-cell.content-cell-type p {
  margin-top: 15px;
}
#DivMain #DivMainCenterWrapper #ActivityLog .content-row .content-cell.width33 {
  width: 33%;
}
#DivMain #DivMainCenterWrapper #ActivityLog .content-row .content-cell.width50 {
  width: 50%;
}
#DivMain #DivMainCenterWrapper #ActivityLog .content-row .link-button {
  margin: 15px 0 10px;
}
#DivMain #DivMainCenterWrapper #ActivityLog #SearchActivityLogGrid {
  margin-top: 10px;
}
#DivMain #DivMainCenterWrapper #ActivityLog #SearchActivityLogGrid .k-grid-header tr th {
  font-size: 11px;
  font-weight: bold;
}
#DivMain #DivMainCenterWrapper #ActivityLog #SearchActivityLogGrid .k-grid-content tr td,
#DivMain #DivMainCenterWrapper #ActivityLog #SearchActivityLogGrid .k-grid-content-locked tr td {
  font-size: 11px;
}
#DivMain #DivMainCenterWrapper #ActivityLog #SearchActivityLogGrid .k-grid-footer tr td {
  background-color: #f3f3f4;
  font-size: 11px;
  font-weight: bold;
}
.loader-area {
  position: relative;
}
.hidden {
  display: none;
}
input[type=text],
input[type=email],
input[type=password] {
  border: 1px solid #b2b2b2;
  padding: 2px;
}
input[type=checkbox][disabled] {
  background-color: #f0f0f0;
  opacity: 0.3;
}
.loading {
  display: none;
  left: 0;
  top: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url(/App_Images/modal.png);
  z-index: 10000;
}
.loading .loader {
  height: 53px;
  width: 50px;
  background-image: url(/App_Images/loader.gif);
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -25px;
  margin-top: -26px;
}
.k-loading-mask {
  background-image: url(/App_Images/modal.png);
  z-index: 100;
}
.k-loading-mask .k-loading-image {
  background-image: url(/App_Images/loader.gif);
  height: 53px;
  width: 50px;
  top: 50%;
  left: 50%;
  margin-left: -25px;
  margin-top: -26px;
  z-index: 10000000000;
}
.aa-fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10000;
  background-color: #fff;
  padding: 10px 0 10px 10px;
  box-sizing: border-box;
}
.aa-fullscreen .aa-fullscreen-inner {
  width: 100%;
  height: 100%;
  overflow: scroll;
  overflow-x: hidden;
  padding-right: 10px;
  box-sizing: border-box;
}
.aa-fullscreen .aa-fullscreen-inner > .k-grid,
.aa-fullscreen .aa-fullscreen-inner > .k-editor {
  height: 100% !important;
}
.k-progressbar {
  margin-top: 10px;
  width: 100%;
}
.k-progressbar .k-state-selected {
  background-color: #def1f8;
  border-color: #def1f8;
}
a.k-state-disabled {
  opacity: 0.3 !important;
}
.nav-selector .k-dropdown {
  display: block;
  margin-bottom: 10px;
}
.pdf-nav-selector .k-dropdown {
  display: block;
  margin-bottom: 10px;
}
.audiovideo-nav-selector .k-dropdown {
  display: block;
  margin-bottom: 10px;
}
.illustration-nav-selector .k-dropdown {
  display: block;
  margin-bottom: 10px;
}
.openfile-nav-selector .k-dropdown {
  display: block;
  margin-bottom: 10px;
}
input[type=text] {
  box-shadow: none;
}
.LinkPfeil {
  width: 6px;
  height: 7px;
  border: none;
}
.kendo-template {
  display: none;
}
fieldset {
  border: none;
  padding: 0;
  margin: 0;
}
fieldset legend {
  display: none;
}
.dotted-spacer {
  border-top: 1px dotted #000;
  width: 100%;
  height: 1px;
  margin-bottom: 10px;
}
.text-right {
  text-align: right;
}
.LinkIntern {
  height: 9px;
  width: 9px;
}
.ImageLogin {
  width: 333px;
  height: 140px;
  border: none;
}
.ImageHelp {
  width: 13px;
  height: 13px;
}
.ContentImage img {
  width: 100%;
  height: 140px;
  border: none;
}
.link-intern {
  background: url(../App_Images/globals/linkInternal.gif) no-repeat left 5px;
  padding-left: 10px;
  cursor: pointer;
}
.link-intern.grey {
  color: #777;
  background-image: url(../App_Images/globals/linkIntern-deak.gif);
}
.trenner-arrow {
  background: url(../App_Images/globals/linkInternal.gif) no-repeat left 5px;
  cursor: default;
  padding-left: 10px;
  margin-left: 2px;
}
.link-extern,
a[target=_blank] {
  background: url(../App_Images/globals/linkIntern.gif) no-repeat left 3px;
  padding-left: 15px;
  cursor: pointer;
}
.link-extern.grey,
a[target=_blank].grey {
  color: #777;
  background-image: url(../App_Images/globals/linkIntern-deak.gif);
}
span.compare-password {
  display: none;
}
.k-edit-label {
  text-align: left;
}
.info-box {
  display: none;
}
table tr td.text-label {
  padding-top: 4px;
}
table tr td .text-label {
  margin-top: 5px;
}
.table-normal {
  font-size: 16px;
}
.table-normal td {
  padding-right: 10px;
  vertical-align: top;
}
.table-normal td .link-button,
.table-normal td .link-button:before,
.table-normal td .link-button:after {
  display: inline-block;
}
.table-privacy {
  width: 100%;
}
.table-privacy thead tr td {
  font-weight: bold;
  padding-bottom: 10px;
}
.table-privacy tr td {
  color: #515967;
  font-size: 12px;
}
table.table-form tr td {
  font-size: 12px;
}
table.table-form tr td .link-button,
table.table-form tr td .link-button:before,
table.table-form tr td .link-button:after {
  display: inline-block;
}
table.table-form.table-profile tr td {
  min-width: 150px;
}
table.table-form.table-profile tr td .k-dropdown {
  width: 205px;
}
table.table-form.table-profile tr td input {
  width: 199px;
}
.checkbox {
  zoom: 1;
  display: block;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.checkbox:before,
.checkbox:after {
  content: "";
  display: table;
}
.checkbox:after {
  clear: both;
}
.checkbox input {
  float: left;
  width: auto;
  margin: 0;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}
.checkbox span {
  float: left;
  width: auto;
  margin-top: 0px !important;
  padding-left: 10px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.video-desc-table td {
  font-size: 12px;
  padding-right: 20px;
}
button.link-button {
  padding: 0;
  box-sizing: content-box;
  font-size: 12px;
  font-family: Arial;
  text-align: start;
}
.link-button {
  zoom: 1;
  padding-left: 5px;
  height: 22px;
  display: inline-block;
  background: url(/App_Images/buttons/btn-o-left.png) no-repeat;
  cursor: pointer;
  border: none;
}
.link-button:before,
.link-button:after {
  content: "";
  display: table;
}
.link-button:after {
  clear: both;
}
.link-button:first-child {
  margin-right: 10px;
}
.link-button.grey,
.link-button.back-button {
  background-image: url(/App_Images/buttons/btn-g-left.png);
}
.link-button.grey .text,
.link-button.back-button .text {
  background-image: url(/App_Images/buttons/btn-g-mid.png);
  color: #004a96;
  font-size: 12px;
}
.link-button.grey .right,
.link-button.back-button .right {
  background-image: url(/App_Images/buttons/btn-g-right.png);
}
.link-button .text {
  background: url(/App_Images/buttons/btn-o-mid.png) repeat-x;
  display: block;
  height: 22px;
  line-height: 22px;
  float: left;
  padding: 0 10px;
  color: #fff;
  font-weight: bold;
}
.link-button .right {
  float: left;
  background: url(/App_Images/buttons/btn-o-right.png) repeat-x;
  display: block;
  height: 22px;
  width: 5px;
}
#Disclaimer ol {
  padding-left: 20px;
}
#NewsletterWrapper .newsletter-header {
  border: 1px solid #dbdbde;
  padding: 5px;
  width: 515px;
}
#NewsletterWrapper .newsletter-header p {
  margin-top: 0;
  margin-bottom: 5px;
}
#NewsletterWrapper .newsletter-header .newsletter-subject-textbox {
  width: 509px;
}
#NewsletterWrapper .newsletter-content .k-button-group .k-tool {
  background-color: #fff;
  height: 24px;
  width: 24px;
}
#NewsletterWrapper .newsletter-content .k-button-group .k-tool.k-state-selected {
  background-color: #cccccc;
  border-color: #b6dbca;
}
#NewsletterWrapper .newsletter-content .k-button-group .k-tool:hover {
  background-color: #cccccc;
}
#NewsletterWrapper .k-dropdown .k-dropdown-wrap .k-select .k-icon {
  margin-top: 0;
}
.newsletter-footer {
  margin-top: 10px;
  zoom: 1;
}
.newsletter-footer:before,
.newsletter-footer:after {
  content: "";
  display: table;
}
.newsletter-footer:after {
  clear: both;
}
.newsletter-footer .footer-left {
  float: left;
  text-align: left;
  width: 50%;
}
.newsletter-footer .footer-right {
  float: left;
  text-align: right;
  width: 50%;
}
.link-list {
  padding: 0;
  list-style-type: none;
  margin-top: 0px;
}
.link-list li {
  margin-bottom: 2px;
}
#DivRightBar .show-logged-in,
#DivRightBar .show-logged-out {
  display: none;
}
.show-only-logged-in {
  display: none;
}
#DivWrapper .margin10 {
  margin: 10px;
}
#DivHeader {
  background-image: url('/App_Images/globals/Header_EN.jpg');
}
#DivHeader #DivHeaderNavBarContent .RadMenu ul.rmRootGroup {
  float: none;
  list-style: none;
  padding: 0;
  margin: 0;
}
#DivHeader #DivHeaderNavBarContent .RadMenu .rmItem {
  float: left;
  display: none;
}
#DivHeader #DivHeaderNavBarContent .RadMenu .rmItem:first-child {
  display: block;
}
#DivHeader #DivHeaderNavBarContent .RadMenu .rmItem .rmLink {
  display: block;
}
.help-page-wrapper p img,
.k-window-content p img {
  height: 12px;
  width: auto;
  margin: 0 3px;
}
.Title {
  position: relative;
}
.Title .help-button,
.Title .collapse-trigger {
  width: 11px;
  height: 11px;
  position: absolute;
  right: 10px;
  top: 5px;
  cursor: pointer;
}
.Title .collapse-trigger {
  background-image: url(../App_Images/buttons/content-hide.png);
}
.Title .collapse-trigger.closed {
  background-image: url(../App_Images/buttons/content-show.png);
}
.Title .box-help .help-button {
  background: url(../App_Images/globals/Fragezeichen.gif) no-repeat;
}
.Title .box-help .help-text {
  display: none;
}
.deleteHiddenItems {
  display: none;
}
.deleteHiddenItems .delete-button {
  background: url(/App_Images/buttons/delete-all.png) no-repeat;
  cursor: pointer;
  height: 11px;
  width: 11px;
  right: 10px;
  top: 5px;
  position: absolute;
}
#DivCenter.LightBox {
  width: 98%;
}
#DivMain {
  margin-top: -10px;
  display: none;
}
#DivMain #BreadcrumbWrapper {
  height: 25px;
  padding-left: 200px;
  padding-top: 10px;
  color: #858585;
  font-size: 11px;
}
#DivMain #BreadcrumbWrapper #Breadcrumb {
  padding-top: 7px;
  zoom: 1;
}
#DivMain #BreadcrumbWrapper #Breadcrumb:before,
#DivMain #BreadcrumbWrapper #Breadcrumb:after {
  content: "";
  display: table;
}
#DivMain #BreadcrumbWrapper #Breadcrumb:after {
  clear: both;
}
#DivMain #BreadcrumbWrapper #Breadcrumb p {
  margin: 0 5px 0 0;
  float: left;
}
#DivMain #BreadcrumbWrapper #Breadcrumb p span {
  margin-right: 5px;
}
#DivMain #BreadcrumbWrapper #Breadcrumb p span.blue {
  color: #004a96;
}
#DivMain #DivMainCenterWrapper {
  zoom: 1;
  width: 717px;
  margin-left: 20px;
  float: left;
}
#DivMain #DivMainCenterWrapper:before,
#DivMain #DivMainCenterWrapper:after {
  content: "";
  display: table;
}
#DivMain #DivMainCenterWrapper:after {
  clear: both;
}
#DivMain #DivMainCenterWrapper .ContextModulFirst {
  width: 354px;
  margin-bottom: 5px;
}
#DivMain #DivMainCenterWrapper .ContextModulFirst .Title {
  position: relative;
}
#DivMain #DivMainCenterWrapper .ContextModulFirst .Title .TitleInner {
  margin: 0px 0px 0px 10px;
}
#DivMain #DivMainCenterWrapper .ContextModulFirst .Content {
  height: 275px;
  position: relative;
  overflow: hidden;
}
#DivMain #DivMainCenterWrapper .ContextModulFirst .Content .ContentInner {
  padding: 0 5px 0 5px;
  margin: 5px;
}
#DivMain #DivMainCenterWrapper .ContextModul {
  margin-left: 5px;
  width: 545px;
  margin-bottom: 5px;
}
#DivMain #DivMainCenterWrapper .ContextModul.LightBox {
  width: 100%;
}
#DivMain #DivMainCenterWrapper .ContextModul.ContextModulHome {
  width: 354px;
}
#DivMain #DivMainCenterWrapper .ContextModul .Title {
  position: relative;
}
#DivMain #DivMainCenterWrapper .ContextModul .Title .TitleInner {
  margin: 0px 0px 0px 10px;
}
#DivMain #DivMainCenterWrapper .ContextModul .Content {
  height: 275px;
  position: relative;
  overflow: hidden;
}
#DivMain #DivMainCenterWrapper .ContextModul .Content .k-invalid-msg {
  color: #bb2222;
  position: absolute;
  background-color: #fff;
  border: none;
  box-shadow: none;
  margin-top: 2px;
  background-image: none;
}
#DivMain #DivMainCenterWrapper .ContextModul .Content .k-invalid-msg.compare-password {
  margin-left: 151px;
}
#DivMain #DivMainCenterWrapper .ContextModul .Content .k-invalid-msg .k-warning {
  display: none;
}
#DivMain #DivMainCenterWrapper .ContextModul .Content .k-invalid-msg .k-callout {
  display: none;
}
#DivMain #DivMainCenterWrapper .ContextModul .Content input[type=text],
#DivMain #DivMainCenterWrapper .ContextModul .Content input[type=email],
#DivMain #DivMainCenterWrapper .ContextModul .Content input[type=password] {
  box-shadow: none;
}
#DivMain #DivMainCenterWrapper .ContextModul .Content.dynamic-height {
  height: auto;
  min-height: 100px;
}
#DivMain #DivMainCenterWrapper .ContextModul .Content .ContentInner {
  padding: 0 5px 0 5px;
  margin: 5px;
}
#DivMain #DivMainCenterWrapper .ContextModulDynamic .Content {
  height: auto;
}
#DivMain #DivMainCenterWrapper .ContextModul2Grid {
  width: 715px;
  margin-bottom: 5px;
}
#DivMain #DivMainCenterWrapper .ContextModul2Grid:not(.ContextModul100) .Content {
  height: auto;
  position: relative;
  overflow: hidden;
  zoom: 1;
}
#DivMain #DivMainCenterWrapper .ContextModul2Grid:not(.ContextModul100) .Content:before,
#DivMain #DivMainCenterWrapper .ContextModul2Grid:not(.ContextModul100) .Content:after {
  content: "";
  display: table;
}
#DivMain #DivMainCenterWrapper .ContextModul2Grid:not(.ContextModul100) .Content:after {
  clear: both;
}
#DivMain #DivMainCenterWrapper .ContextModul2Grid:not(.ContextModul100) .Content .ContentInner {
  float: left;
  width: 395px;
}
#DivMain #DivMainCenterWrapper .ContextModul2Grid:not(.ContextModul100) .Content .ContentImage {
  height: 212px;
  float: right;
  overflow: hidden;
  width: 300px;
}
#DivMain #DivMainCenterWrapper .ContextModul2Grid:not(.ContextModul100) .Content .ContentImage img {
  height: 212px;
  margin-left: -50px;
  width: 350px;
}
#DivMain #DivMainCenterWrapper .ContentSliderLoggedOut,
#DivMain #DivMainCenterWrapper .ContentSliderLoggedIn {
  zoom: 1;
}
#DivMain #DivMainCenterWrapper .ContentSliderLoggedOut:before,
#DivMain #DivMainCenterWrapper .ContentSliderLoggedIn:before,
#DivMain #DivMainCenterWrapper .ContentSliderLoggedOut:after,
#DivMain #DivMainCenterWrapper .ContentSliderLoggedIn:after {
  content: "";
  display: table;
}
#DivMain #DivMainCenterWrapper .ContentSliderLoggedOut:after,
#DivMain #DivMainCenterWrapper .ContentSliderLoggedIn:after {
  clear: both;
}
#DivMain #DivMainCenterWrapper .ContentSliderLoggedOut .SliderElement,
#DivMain #DivMainCenterWrapper .ContentSliderLoggedIn .SliderElement {
  width: 354px;
  height: 275px;
  float: left;
}
#DivMain #DivMainCenterWrapper .ContentSliderLoggedOut .ContentInner,
#DivMain #DivMainCenterWrapper .ContentSliderLoggedIn .ContentInner {
  margin-left: 5px !important;
  margin-right: 5px !important;
}
#DivMain #DivMainCenterWrapper .ContentSliderButtons {
  position: absolute;
  top: 103px;
  zoom: 1;
  width: 100%;
}
#DivMain #DivMainCenterWrapper .ContentSliderButtons:before,
#DivMain #DivMainCenterWrapper .ContentSliderButtons:after {
  content: "";
  display: table;
}
#DivMain #DivMainCenterWrapper .ContentSliderButtons:after {
  clear: both;
}
#DivMain #DivMainCenterWrapper .ContentSliderButtons .prev {
  cursor: pointer;
  width: 25px;
  height: 37px;
  float: left;
  background: url(../App_Images/buttons/slider-back.jpg) no-repeat;
}
#DivMain #DivMainCenterWrapper .ContentSliderButtons .next {
  cursor: pointer;
  width: 25px;
  height: 37px;
  float: right;
  background: url(../App_Images/buttons/slider-ahead.jpg) no-repeat;
}
#DivMain #DivRightBar .ContentBox {
  margin-bottom: 10px;
}
#DivMain #DivRightBar .ContentBox .ContentInner {
  margin: 7px 10px 5px 5px;
}
.k-invalid-msg {
  border-radius: 0;
}
.k-invalid {
  box-shadow: none;
  border: 1px solid #b22;
}
.editor-icon {
  background-image: url('/App_Images/MediaList/icon_ausblenden.png');
  display: block;
  height: 20px;
  position: absolute;
  right: 0;
  width: 20px;
}
.k-dropdown,
.k-combobox {
  height: 20px;
}
.k-dropdown .k-dropdown-wrap,
.k-combobox .k-dropdown-wrap {
  border-radius: 0;
  height: 18px;
  box-shadow: none;
  background-color: #fff !important;
  border-color: #b2b2b2;
}
.k-dropdown .k-dropdown-wrap.k-state-hover,
.k-combobox .k-dropdown-wrap.k-state-hover,
.k-dropdown .k-dropdown-wrap.k-state-focused,
.k-combobox .k-dropdown-wrap.k-state-focused {
  border-radius: 0;
  background: none;
  border-color: #7eb4ea;
}
.k-dropdown .k-dropdown-wrap.k-state-hover .k-select,
.k-combobox .k-dropdown-wrap.k-state-hover .k-select,
.k-dropdown .k-dropdown-wrap.k-state-focused .k-select,
.k-combobox .k-dropdown-wrap.k-state-focused .k-select {
  border-radius: 0;
  border-left: 1px solid #7eb4ea;
}
.k-dropdown .k-dropdown-wrap .k-input,
.k-combobox .k-dropdown-wrap .k-input {
  padding: 0;
  line-height: 18px;
  height: 18px;
  color: #000;
}
.k-dropdown .k-dropdown-wrap .k-select,
.k-combobox .k-dropdown-wrap .k-select {
  border-radius: 0;
  width: 15px;
  border-left: 1px solid #fff;
  height: 18px;
  min-height: 0;
}
.k-dropdown .k-dropdown-wrap .k-select .k-icon,
.k-combobox .k-dropdown-wrap .k-select .k-icon {
  margin-left: -1px;
  margin-top: -5px;
}
.k-dropdown[aria-disabled=true] .k-dropdown-wrap,
.k-combobox[aria-disabled=true] .k-dropdown-wrap {
  border-color: #dbdbde;
}
.k-dropdown[aria-disabled=true] .k-dropdown-wrap .k-select,
.k-combobox[aria-disabled=true] .k-dropdown-wrap .k-select {
  background-color: transparent;
}
.k-animation-container .k-list-container {
  border-radius: 0;
  border-color: #b2b2b2;
  color: #000;
  padding-top: 0;
  padding-bottom: 0;
  background-color: #fff;
  box-shadow: none;
}
.k-animation-container .k-list-container .k-list-optionlabel {
  background-image: none !important;
  background-color: #3399ff !important;
  border-radius: 0;
  border: none !important;
  box-shadow: none;
  color: #fff !important;
  font-size: 12px;
  /*left: 2px;*/
  line-height: 1.8em;
  /*margin: 0 0 0 -4px;*/
  padding: 0 2px;
  position: relative;
}
.k-animation-container .k-list-container .k-list-optionlabel span {
  color: #fff !important;
}
.k-animation-container .k-list-container .k-list {
  /*margin-left: -4px;*/
  position: relative;
  /*left: 2px;*/
  /*height: auto !important;*/
}
.k-animation-container .k-list-container .k-list .k-item {
  margin: 0;
  border-radius: 0;
  border: none !important;
  padding: 0 2px;
}
.k-animation-container .k-list-container .k-list .k-item.k-state-hover,
.k-animation-container .k-list-container .k-list .k-item.k-state-focused,
.k-animation-container .k-list-container .k-list .k-item.k-state-selected {
  background-image: none !important;
  background-color: #3399ff !important;
  box-shadow: none !important;
  color: #fff !important;
}
.k-animation-container .k-list-container .k-list .k-item.k-state-hover span,
.k-animation-container .k-list-container .k-list .k-item.k-state-focused span,
.k-animation-container .k-list-container .k-list .k-item.k-state-selected span {
  color: #fff !important;
}
.k-combobox .k-input {
  padding: 0 2px !important;
}
#DivWrapper .k-grid .k-button:not(.k-grid-add):not(.k-grid-cancel-changes):not(.k-grid-excel):not(.k-grid-ToggleFullScreen) {
  width: 20px;
  height: 20px;
  border: none;
  min-width: 0;
  border-radius: 0;
  color: transparent;
  text-indent: -10000%;
  padding: 0;
}
#DivWrapper .k-grid .k-button:not(.k-grid-add):not(.k-grid-cancel-changes):not(.k-grid-excel):not(.k-grid-ToggleFullScreen).k-grid-add {
  width: 100px;
  border-width: 1px;
  border-style: solid;
  height: auto;
  color: black;
  text-indent: 0;
  margin: 5px 5px;
}
#DivWrapper .k-grid .k-button:not(.k-grid-add):not(.k-grid-cancel-changes):not(.k-grid-excel):not(.k-grid-ToggleFullScreen) span {
  display: none;
}
#DivWrapper .k-grid .k-grid-edit {
  background: url(/App_Images/buttons/icon-edit.png) no-repeat;
}
#DivWrapper .k-grid .k-grid-delete,
#DivWrapper .k-grid .k-grid-custom-delete {
  background: url(/App_Images/buttons/icon-delete.png) no-repeat;
}
#DivWrapper .k-grid .k-grid-update {
  background: url(/App_Images/buttons/icon-save_green.png) no-repeat;
}
#DivWrapper .k-grid .k-grid-cancel,
#DivWrapper .k-grid .k-grid-custom-delete-cancel {
  background: url(/App_Images/buttons/icon-abbort_grey.png) no-repeat;
}
.password-validation-wrapper {
  margin-bottom: 15px;
}
.k-window {
  border-radius: 0;
  min-width: 350px;
  border: 1px solid #ccc;
}
.k-window .k-window-titlebar {
  border-radius: 0;
  padding: 0;
  height: 25px;
  border: none;
}
.k-window .k-window-titlebar .k-window-title {
  color: #fff;
  font-size: 11px;
  font-weight: bold;
  left: 10px;
  line-height: 25px;
  padding-left: 10px;
}
.k-window .k-window-titlebar .k-window-actions .k-window-action {
  background: none !important;
  border-color: transparent !important;
}
.k-window .k-window-titlebar .k-window-actions .k-window-action .k-i-close {
  background: url(../App_Images/buttons/close.png);
  background-repeat: no-repeat;
}
.k-window .k-window-titlebar .k-window-actions .k-window-action .k-i-close:before {
  content: none;
}
.k-window .k-window-content {
  padding: 10px;
}
#DivMain #DivMainCenterWrapper #DataProtection {
  height: auto;
}
#DivMain #DivMainCenterWrapper #DataProtection ol {
  counter-reset: item;
}
#DivMain #DivMainCenterWrapper #DataProtection ol > li {
  display: block;
}
#DivMain #DivMainCenterWrapper #DataProtection ol > li:before {
  content: counters(item, ".") ". ";
  counter-increment: item;
  font-weight: bold;
}
#DivMain #DivMainCenterWrapper #DataProtection ol > li > span {
  font-weight: bold;
}
#DivMain #DivMainCenterWrapper #DataProtection td {
  border: 0.5px solid;
  font-size: 100%;
}
#UserLoggedIn {
  display: none;
}
#UserLoggedIn,
#UserLoggedIn * {
  color: #999999;
}
#UserLoggedIn strong {
  margin-right: 10px;
}
#UserLoggedOut {
  width: 700px;
}
#UserLoggedOut #LoginFieldset {
  border: 0;
  padding-top: 3px;
}
#UserLoggedOut #LoginFieldset legend {
  display: none;
}
#UserLoggedOut #LoginFieldset .login-field {
  float: left;
  display: block;
}
#UserLoggedOut #LoginFieldset #txtUsername {
  width: 127px;
  margin-left: 6px;
  margin-right: 10px;
  padding: 2px;
}
#UserLoggedOut #LoginFieldset #txtPassword {
  width: 127px;
  margin-left: 6px;
  margin-right: 10px;
  padding: 2px;
}
#UserLoggedOut #LoginFieldset #LblStayLoggedIn {
  margin-right: 10px;
  /*background-color: #fff;*/
  height: 22px;
  display: inline-block;
  width: 140px;
  float: left;
  margin-top: 2px;
}
#UserLoggedOut #LoginFieldset #LblStayLoggedIn input {
  float: left;
}
#UserLoggedOut #LoginFieldset #LblStayLoggedIn span {
  float: left;
  margin: 2px 0 0 5px;
}
#UserLoggedOut #LoginFieldset #btnLogin {
  float: left;
}
#UserLoggedOut #LoginFieldset .k-invalid-msg {
  display: none !important;
}
#NavLeftWrapper .nav-left-title {
  color: #004a96;
  font-size: 11px;
  padding: 5px 0 0 10px;
  border-bottom: 2px solid #7ac5e4;
  margin: 0;
}
#NavLeftWrapper .nav-left {
  margin: 4px 0 0 0;
  white-space: normal;
}
#NavLeftWrapper .nav-left .k-treeview-lines .k-icon {
  display: none;
}
#NavLeftWrapper .nav-left .k-treeview-lines .k-group {
  padding-top: 2px;
}
#NavLeftWrapper .nav-left .k-treeview-lines .k-item {
  width: 100%;
  background-image: none;
}
#NavLeftWrapper .nav-left .k-treeview-lines .k-item div {
  background-image: none !important;
}
#NavLeftWrapper .nav-left .k-treeview-lines .k-item .k-in {
  display: block;
  width: 136px;
  color: #fff;
  font-size: 11px;
  padding: 5px 12px;
  margin: 0;
  border: none;
  border-radius: 0;
  cursor: pointer;
  background-image: none !important;
  box-shadow: none;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}
#NavLeftWrapper .nav-left .k-treeview-lines .k-item.k-last {
  padding: 0;
}
#NavLeftWrapper .nav-left .k-treeview-lines > .k-item > div > .k-in {
  background-color: #7ac5e4;
}
#NavLeftWrapper .nav-left .k-treeview-lines > .k-item > div > .k-in.k-state-selected {
  background-color: #4db3dc;
  color: #fff;
  font-weight: 700;
}
#NavLeftWrapper .nav-left .k-treeview-lines > .k-item > div > .k-in.k-state-selected:hover {
  background-color: #def1f8;
  color: #004a96;
}
#NavLeftWrapper .nav-left .k-treeview-lines > .k-item > div > .k-in:hover {
  background-color: #def1f8;
  color: #004a96;
  font-weight: 700;
}
#NavLeftWrapper .nav-left .k-treeview-lines > .k-item[data-expanded=true] > div > .k-in {
  background-color: #4db3dc;
}
#NavLeftWrapper .nav-left .k-treeview-lines > .k-item[data-expanded=true] > div > .k-in.k-state-hover {
  background-color: #def1f8;
}
#NavLeftWrapper .nav-left .k-treeview-lines > .k-item > ul > .k-item > div > .k-in {
  background-color: #bce1f1;
  color: #004a96;
  font-weight: 400;
}
#NavLeftWrapper .nav-left .k-treeview-lines > .k-item > ul > .k-item > div > .k-in.k-state-selected {
  background-color: #85cbe6;
  font-weight: 700;
}
#NavLeftWrapper .nav-left .k-treeview-lines > .k-item > ul > .k-item > div > .k-in:hover {
  background-color: #def1f8;
  font-weight: 700;
}
#NavLeftWrapper .nav-left .k-treeview-lines > .k-item > ul > .k-item[aria-expanded=true] > div > .k-in.k-state-hover {
  background-color: #def1f8;
}
#NavLeftWrapper .nav-left .k-treeview-lines > .k-item > ul > .k-item > ul > .k-item > div > .k-in {
  color: #004a96;
  background-color: #def1f8;
  font-weight: 400;
}
#NavLeftWrapper .nav-left .k-treeview-lines > .k-item > ul > .k-item > ul > .k-item > div > .k-in:hover,
#NavLeftWrapper .nav-left .k-treeview-lines > .k-item > ul > .k-item > ul > .k-item > div > .k-in.k-state-selected {
  font-weight: 700;
}
#NavLeftWrapper .nav-left li {
  width: 100%;
  margin: 0;
  padding: 0 0 2px 0;
}
#NavLeftWrapper .nav-left li a {
  display: block;
  width: 136px;
  color: #fff;
  background-color: #7ac5e4;
  font-size: 11px;
  font-weight: 700;
  padding: 5px 12px;
}
#NavLeftWrapper .nav-left li a:hover,
#NavLeftWrapper .nav-left li a.active {
  background-color: #def1f8;
}
[data-media-type="1"] table tr.Picture {
  display: table-row;
}
.lightbox-name-wrapper {
  display: none;
}
#MediaPreview .preview-image-wrapper {
  width: 510px;
  height: 380px;
  text-align: center;
  margin-bottom: 10px;
}
#MediaPreview .preview-image-wrapper .preview-image {
  border: none;
  height: auto;
  width: auto;
  /*max-width: 510px;
            max-height: 380px;*/
}
#MediaPreview .preview-video-wrapper .VideoWrapper {
  width: 510px;
  height: 330px;
  margin-top: 15px;
}
#MediaPreview [data-is-youtube=true] {
  display: none;
}
#MediaPreview [data-is-downloadable=false] {
  display: none;
}
#MediaPreview .media-options .can-disable-media-item[data-is-hidden=false] .show {
  display: none;
}
#MediaPreview .media-options .can-disable-media-item[data-is-hidden=true] .hide {
  display: none;
}
#MediaPreview table {
  width: 100%;
  border-collapse: collapse;
}
#MediaPreview table tr td {
  padding: 0;
}
#MediaPreview table tr td.cell-left {
  width: 60%;
}
#MediaPreview table tr td .box-grey {
  background-color: #f1f1f1;
  padding: 8px 8px 10px 8px;
}
#MediaPreview table tr td .box-white {
  background-color: #fff;
  padding: 8px;
}
#MediaPreview table tr td a.link-intern {
  display: block;
  margin-bottom: 5px;
}
#MediaPreview table tr td .resolution-wrapper {
  zoom: 1;
}
#MediaPreview table tr td .resolution-wrapper:before,
#MediaPreview table tr td .resolution-wrapper:after {
  content: "";
  display: table;
}
#MediaPreview table tr td .resolution-wrapper:after {
  clear: both;
}
#MediaPreview table tr td .resolution-wrapper .ResolutionSelector {
  width: 175px;
  float: left;
}
#MediaPreview table tr td .resolution-wrapper .btnDownload {
  float: right;
  margin-top: 3px;
}
#MediaPreview table tr td .language-wrapper {
  zoom: 1;
}
#MediaPreview table tr td .language-wrapper:before,
#MediaPreview table tr td .language-wrapper:after {
  content: "";
  display: table;
}
#MediaPreview table tr td .language-wrapper:after {
  clear: both;
}
#MediaPreview table tr td .language-wrapper .LanguageSelector {
  width: 175px;
  float: left;
}
#MediaPreview table tr td .language-wrapper .btnDownload {
  float: right;
  margin-top: 3px;
}
#MediaPreview table tr td .lightbox-wrapper {
  zoom: 1;
  margin-bottom: 5px;
}
#MediaPreview table tr td .lightbox-wrapper:before,
#MediaPreview table tr td .lightbox-wrapper:after {
  content: "";
  display: table;
}
#MediaPreview table tr td .lightbox-wrapper:after {
  clear: both;
}
#MediaPreview table tr td .lightbox-wrapper .LightBoxSelector {
  width: 220px;
}
#MediaPreview table tr td .lightbox-wrapper .link-button {
  float: right;
  margin-top: 3px;
}
#MediaPreview table tr td .further-infos-wrapper {
  padding: 5px 20px;
}
#MediaPreview table tr td .btnPlay {
  margin-left: 10px;
}
#MediaPreview table tr td .lightbox-name-wrapper {
  padding-top: 0;
  padding-bottom: 15px;
}
#MediaPreview .ShortUrlTextbox,
#MediaPreview .LightBoxNameTextbox {
  width: 280px;
}
.culture-filter {
  display: none;
}
#DivMain #DivMainCenterWrapper #AdvancedSearch,
#DivMain #DivMainCenterWrapper #MediaMergeWrapper {
  height: auto;
}
#DivMain #DivMainCenterWrapper #AdvancedSearch .table-advanced-search,
#DivMain #DivMainCenterWrapper #MediaMergeWrapper .table-advanced-search,
#DivMain #DivMainCenterWrapper #AdvancedSearch .table-media-merge,
#DivMain #DivMainCenterWrapper #MediaMergeWrapper .table-media-merge {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
}
#DivMain #DivMainCenterWrapper #AdvancedSearch .table-advanced-search tr,
#DivMain #DivMainCenterWrapper #MediaMergeWrapper .table-advanced-search tr,
#DivMain #DivMainCenterWrapper #AdvancedSearch .table-media-merge tr,
#DivMain #DivMainCenterWrapper #MediaMergeWrapper .table-media-merge tr {
  font-size: 16px;
}
#DivMain #DivMainCenterWrapper #AdvancedSearch .table-advanced-search tr.box-grey,
#DivMain #DivMainCenterWrapper #MediaMergeWrapper .table-advanced-search tr.box-grey,
#DivMain #DivMainCenterWrapper #AdvancedSearch .table-media-merge tr.box-grey,
#DivMain #DivMainCenterWrapper #MediaMergeWrapper .table-media-merge tr.box-grey {
  background-color: #f1f1f1;
  padding: 5px;
  vertical-align: middle;
}
#DivMain #DivMainCenterWrapper #AdvancedSearch .table-advanced-search tr.box-grey td,
#DivMain #DivMainCenterWrapper #MediaMergeWrapper .table-advanced-search tr.box-grey td,
#DivMain #DivMainCenterWrapper #AdvancedSearch .table-media-merge tr.box-grey td,
#DivMain #DivMainCenterWrapper #MediaMergeWrapper .table-media-merge tr.box-grey td {
  color: #004a96;
  font-size: 75%;
  padding: 3px 5px;
}
#DivMain #DivMainCenterWrapper #AdvancedSearch .table-advanced-search tr td,
#DivMain #DivMainCenterWrapper #MediaMergeWrapper .table-advanced-search tr td,
#DivMain #DivMainCenterWrapper #AdvancedSearch .table-media-merge tr td,
#DivMain #DivMainCenterWrapper #MediaMergeWrapper .table-media-merge tr td {
  padding-bottom: 15px;
  vertical-align: top;
}
#DivMain #DivMainCenterWrapper #AdvancedSearch .table-advanced-search tr td .additional-suggestions-wrapper,
#DivMain #DivMainCenterWrapper #MediaMergeWrapper .table-advanced-search tr td .additional-suggestions-wrapper,
#DivMain #DivMainCenterWrapper #AdvancedSearch .table-media-merge tr td .additional-suggestions-wrapper,
#DivMain #DivMainCenterWrapper #MediaMergeWrapper .table-media-merge tr td .additional-suggestions-wrapper {
  display: none;
}
#DivMain #DivMainCenterWrapper #AdvancedSearch .table-advanced-search tr td .txt-search-keys,
#DivMain #DivMainCenterWrapper #MediaMergeWrapper .table-advanced-search tr td .txt-search-keys,
#DivMain #DivMainCenterWrapper #AdvancedSearch .table-media-merge tr td .txt-search-keys,
#DivMain #DivMainCenterWrapper #MediaMergeWrapper .table-media-merge tr td .txt-search-keys {
  float: left;
  width: 70%;
}
#DivMain #DivMainCenterWrapper #AdvancedSearch .table-advanced-search tr td .and-or-select,
#DivMain #DivMainCenterWrapper #MediaMergeWrapper .table-advanced-search tr td .and-or-select,
#DivMain #DivMainCenterWrapper #AdvancedSearch .table-media-merge tr td .and-or-select,
#DivMain #DivMainCenterWrapper #MediaMergeWrapper .table-media-merge tr td .and-or-select {
  float: right;
  width: 25%;
}
#DivMain #DivMainCenterWrapper #AdvancedSearch .table-advanced-search tr td .and-or-select .k-dropdown-wrap,
#DivMain #DivMainCenterWrapper #MediaMergeWrapper .table-advanced-search tr td .and-or-select .k-dropdown-wrap,
#DivMain #DivMainCenterWrapper #AdvancedSearch .table-media-merge tr td .and-or-select .k-dropdown-wrap,
#DivMain #DivMainCenterWrapper #MediaMergeWrapper .table-media-merge tr td .and-or-select .k-dropdown-wrap {
  height: 20px;
}
#DivMain #DivMainCenterWrapper #AdvancedSearch .table-advanced-search tr td .and-or-select .k-dropdown-wrap .k-input,
#DivMain #DivMainCenterWrapper #MediaMergeWrapper .table-advanced-search tr td .and-or-select .k-dropdown-wrap .k-input,
#DivMain #DivMainCenterWrapper #AdvancedSearch .table-media-merge tr td .and-or-select .k-dropdown-wrap .k-input,
#DivMain #DivMainCenterWrapper #MediaMergeWrapper .table-media-merge tr td .and-or-select .k-dropdown-wrap .k-input {
  height: 20px;
  line-height: 20px;
}
#DivMain #DivMainCenterWrapper #AdvancedSearch .table-advanced-search tr td .and-or-select .k-dropdown-wrap .k-select,
#DivMain #DivMainCenterWrapper #MediaMergeWrapper .table-advanced-search tr td .and-or-select .k-dropdown-wrap .k-select,
#DivMain #DivMainCenterWrapper #AdvancedSearch .table-media-merge tr td .and-or-select .k-dropdown-wrap .k-select,
#DivMain #DivMainCenterWrapper #MediaMergeWrapper .table-media-merge tr td .and-or-select .k-dropdown-wrap .k-select {
  height: 20px;
}
#DivMain #DivMainCenterWrapper #AdvancedSearch .max-media-items-reached-wrapper,
#DivMain #DivMainCenterWrapper #MediaMergeWrapper .max-media-items-reached-wrapper {
  display: none;
}
#DivMain #DivMainCenterWrapper #AdvancedSearch .max-media-items-reached-wrapper span,
#DivMain #DivMainCenterWrapper #MediaMergeWrapper .max-media-items-reached-wrapper span {
  color: #c50022;
  font-weight: 700;
}
#DivMain #DivMainCenterWrapper .CultureTitle {
  margin-left: 5px;
  margin-bottom: 10px;
}
#DivMain #DivMainCenterWrapper .CultureContent {
  height: auto;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
}
#DivMain #DivMainCenterWrapper .CultureContent .CultureTable {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}
#DivMain #DivMainCenterWrapper .CultureContent .CultureTable tr td {
  font-size: 11px;
  height: 30px;
}
#DivMain #DivMainCenterWrapper .CultureContent .CultureTable tr td:first-child {
  font-weight: bold;
  width: 100px;
}
#DivMain #DivMainCenterWrapper .CultureContent .CultureTable tr td .k-dropdown {
  width: 230px;
}
#DivMain #DivMainCenterWrapper .ContextModul .Content .media-list-wrapper {
  margin: 0 5px;
}
#DivMain #DivMainCenterWrapper .ContextModul .Content .pager-wrapper {
  position: relative;
}
#DivMain #DivMainCenterWrapper .ContextModul .Content .pager-wrapper .show-text {
  position: absolute;
  line-height: 40px;
  right: 70px;
  color: #004a96;
}
#DivMain #DivMainCenterWrapper .ContextModul .Content .media-list-top-wrapper {
  width: 100%;
}
#DivMain #DivMainCenterWrapper .ContextModul .Content .media-list-top-wrapper .back-button-wrapper {
  float: left;
  width: 80px;
  height: 40px;
  line-height: 40px;
}
#DivMain #DivMainCenterWrapper .ContextModul .Content .media-list-top-wrapper .back-button-wrapper .back-button {
  margin: 12px 0 0 7px;
}
#DivMain #DivMainCenterWrapper .ContextModul .Content .media-list-pager {
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
  height: 40px;
  line-height: 40px;
}
#DivMain #DivMainCenterWrapper .ContextModul .Content .media-list-pager .k-pager-first,
#DivMain #DivMainCenterWrapper .ContextModul .Content .media-list-pager .k-pager-last,
#DivMain #DivMainCenterWrapper .ContextModul .Content .media-list-pager .k-pager-info {
  display: none;
}
#DivMain #DivMainCenterWrapper .ContextModul .Content .media-list-pager .k-pager-sizes {
  float: right;
  padding: 0;
}
#DivMain #DivMainCenterWrapper .ContextModul .Content .media-list-pager .k-pager-nav {
  border: none;
  line-height: 40px !important;
  background: none !important;
}
#DivMain #DivMainCenterWrapper .ContextModul .Content .media-list-pager .k-pager-nav .k-i-arrow-w {
  background: url(/App_Images/buttons/btArrowLeftWhite.jpg) no-repeat;
  width: 24px;
  height: 22px;
}
#DivMain #DivMainCenterWrapper .ContextModul .Content .media-list-pager .k-pager-nav .k-i-arrow-e {
  background: url(/App_Images/buttons/btArrowRightWhtie.jpg) no-repeat;
  width: 24px;
  height: 22px;
}
#DivMain #DivMainCenterWrapper .ContextModul .Content .media-list-pager .k-pager-input {
  color: #004a96;
  width: 101px;
  text-align: center;
  font-weight: bold;
}
#DivMain #DivMainCenterWrapper .ContextModul .Content .media-list-pager .k-pager-input .k-textbox {
  color: #004a96;
  border-radius: 0;
  text-align: right;
  border-color: #000;
}
#DivMain #DivMainCenterWrapper .ContextModul .Content .pager-top {
  width: 365px;
  float: left;
  clear: none;
  padding-left: 90px;
}
#DivMain #DivMainCenterWrapper .ContextModul .Content .pager-bottom {
  padding-left: 172px;
}
#DivMain #DivMainCenterWrapper .ContextModul .Content .pager-bottom .k-pager-sizes {
  display: none;
}
#DivMain #DivMainCenterWrapper .ContextModul .Content .media-list-action-wrapper {
  zoom: 1;
  border-top: 1px solid #f1f1f1;
  border-bottom: 1px solid #f1f1f1;
  /*height: 38px;
                    line-height: 38px;*/
  margin-top: 10px;
  margin-bottom: 15px;
}
#DivMain #DivMainCenterWrapper .ContextModul .Content .media-list-action-wrapper:before,
#DivMain #DivMainCenterWrapper .ContextModul .Content .media-list-action-wrapper:after {
  content: "";
  display: table;
}
#DivMain #DivMainCenterWrapper .ContextModul .Content .media-list-action-wrapper:after {
  clear: both;
}
#DivMain #DivMainCenterWrapper .ContextModul .Content .media-list-action-wrapper[data-is-mass-processing-activated=true] .mass-processing-wrapper a.link-button .start-mass-processing {
  display: none;
}
#DivMain #DivMainCenterWrapper .ContextModul .Content .media-list-action-wrapper[data-is-mass-processing-activated=true] .mass-processing-wrapper a.link-button .stop-mass-processing {
  display: block;
}
#DivMain #DivMainCenterWrapper .ContextModul .Content .media-list-action-wrapper[data-is-mass-processing-activated=true] .mass-processing-actions {
  display: block;
}
#DivMain #DivMainCenterWrapper .ContextModul .Content .media-list-action-wrapper[data-is-mass-processing-activated=false] .mass-processing-wrapper a.link-button .start-mass-processing {
  display: block;
}
#DivMain #DivMainCenterWrapper .ContextModul .Content .media-list-action-wrapper[data-is-mass-processing-activated=false] .mass-processing-wrapper a.link-button .stop-mass-processing {
  display: none;
}
#DivMain #DivMainCenterWrapper .ContextModul .Content .media-list-action-wrapper .generate-link-wrapper {
  zoom: 1;
  float: left;
}
#DivMain #DivMainCenterWrapper .ContextModul .Content .media-list-action-wrapper .generate-link-wrapper:before,
#DivMain #DivMainCenterWrapper .ContextModul .Content .media-list-action-wrapper .generate-link-wrapper:after {
  content: "";
  display: table;
}
#DivMain #DivMainCenterWrapper .ContextModul .Content .media-list-action-wrapper .generate-link-wrapper:after {
  clear: both;
}
#DivMain #DivMainCenterWrapper .ContextModul .Content .media-list-action-wrapper .generate-link-wrapper b {
  float: left;
  line-height: 38px;
  display: block;
}
#DivMain #DivMainCenterWrapper .ContextModul .Content .media-list-action-wrapper .generate-link-wrapper a.link-button {
  float: left;
  display: block;
  margin-left: 10px;
  margin-top: 8px;
}
#DivMain #DivMainCenterWrapper .ContextModul .Content .media-list-action-wrapper .mass-processing-wrapper {
  float: right;
}
#DivMain #DivMainCenterWrapper .ContextModul .Content .media-list-action-wrapper .mass-processing-wrapper a.link-button {
  float: right;
  display: block;
  margin-left: 10px;
  margin-top: 8px;
}
#DivMain #DivMainCenterWrapper .ContextModul .Content .media-list-action-wrapper .mass-processing-actions {
  display: none;
  float: left;
  margin: 10px 0;
  width: 100%;
}
#DivMain #DivMainCenterWrapper .ContextModul .Content .media-list-action-wrapper .mass-processing-actions .link-button {
  margin-right: 10px;
}
#DivMain #DivMainCenterWrapper .ContextModul .Content .media-list-action-wrapper .mass-processing-actions .btn-activate,
#DivMain #DivMainCenterWrapper .ContextModul .Content .media-list-action-wrapper .mass-processing-actions .btn-delete,
#DivMain #DivMainCenterWrapper .ContextModul .Content .media-list-action-wrapper .mass-processing-actions .btn-hide,
#DivMain #DivMainCenterWrapper .ContextModul .Content .media-list-action-wrapper .mass-processing-actions .btn-show {
  display: none;
}
#DivMain #DivMainCenterWrapper .ContextModul .Content #MediaList {
  zoom: 1;
  border: none;
  margin-left: -5px;
  border-bottom: 1px solid #f1f1f1;
  margin-bottom: 5px;
}
#DivMain #DivMainCenterWrapper .ContextModul .Content #MediaList:before,
#DivMain #DivMainCenterWrapper .ContextModul .Content #MediaList:after {
  content: "";
  display: table;
}
#DivMain #DivMainCenterWrapper .ContextModul .Content #MediaList:after {
  clear: both;
}
#DivMain #DivMainCenterWrapper .ContextModul .Content #MediaList .media-item {
  height: 220px;
  float: left;
  margin-left: 5px;
  position: relative;
  width: 130px;
}
#DivMain #DivMainCenterWrapper .ContextModul .Content #MediaList .media-item[data-media-type="3"] .video-play-button {
  width: 100%;
  background: url(/App_Images/buttons/icon-video-layer.png) no-repeat center center transparent;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
#DivMain #DivMainCenterWrapper .ContextModul .Content #MediaList .media-item .media-image {
  width: 128px;
  height: 128px;
  border: 1px solid #f1f1f1;
  cursor: pointer;
  position: relative;
  word-wrap: break-word;
}
#DivMain #DivMainCenterWrapper .ContextModul .Content #MediaList .media-item .media-image img {
  width: 128px;
  height: 128px;
  border: none;
}
#DivMain #DivMainCenterWrapper .ContextModul .Content #MediaList .media-item h3 {
  font-weight: normal;
  color: #000;
  font-size: 12px;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}
#DivMain #DivMainCenterWrapper .ContextModul .Content #MediaList .media-item .options {
  zoom: 1;
  margin-top: 10px;
}
#DivMain #DivMainCenterWrapper .ContextModul .Content #MediaList .media-item .options:before,
#DivMain #DivMainCenterWrapper .ContextModul .Content #MediaList .media-item .options:after {
  content: "";
  display: table;
}
#DivMain #DivMainCenterWrapper .ContextModul .Content #MediaList .media-item .options:after {
  clear: both;
}
#DivMain #DivMainCenterWrapper .ContextModul .Content #MediaList .media-item .options .icon {
  display: block;
  float: left;
  height: 20px;
  margin-right: 5px;
  width: 20px;
}
#DivMain #DivMainCenterWrapper .ContextModul .Content #MediaList .media-item .options .icon.NoDownload {
  background-image: url(/App_Images/MediaList/icon_download.png);
  opacity: 0.3;
}
#DivMain #DivMainCenterWrapper .ContextModul .Content #MediaList .media-item .options .icon.Download {
  background-image: url(/App_Images/MediaList/icon_download.png);
}
#DivMain #DivMainCenterWrapper .ContextModul .Content #MediaList .media-item .options .icon.LightBox {
  background-image: url(/App_Images/MediaList/icon_leuchtkasten.png);
}
#DivMain #DivMainCenterWrapper .ContextModul .Content #MediaList .media-item .options .icon.Share {
  background-image: url(/App_Images/MediaList/icon_teilen.png);
}
#DivMain #DivMainCenterWrapper .ContextModul .Content #MediaList .media-item .options .icon.Detail {
  background-image: url(/App_Images/MediaList/icon_details.png);
}
#DivMain #DivMainCenterWrapper .ContextModul .Content #MediaList .media-item .options .icon.Hide {
  background-image: url(/App_Images/MediaList/icon_ausblenden.png);
}
#DivMain #DivMainCenterWrapper .ContextModul .Content #MediaList .media-item .options .icon.Show {
  background-image: url(/App_Images/MediaList/icon_einblenden.png);
}
#DivMain #DivMainCenterWrapper .ContextModul .Content #MediaList .media-item .options .icon.Delete {
  background-image: url(/App_Images/buttons/icon-delete-hidden.png);
}
#DivMain #DivMainCenterWrapper .ContextModul .Content #MediaList .media-item .options .can-disable-media-item {
  float: left;
}
#DivMain #DivMainCenterWrapper .ContextModul .Content #MediaList .media-item .options[data-is-hidden=false] .Show {
  display: none;
}
#DivMain #DivMainCenterWrapper .ContextModul .Content #MediaList .media-item .options[data-is-hidden=false] .Delete {
  display: none;
}
#DivMain #DivMainCenterWrapper .ContextModul .Content #MediaList .media-item .options[data-is-youtube=true] .Download {
  display: none;
}
#DivMain #DivMainCenterWrapper .ContextModul .Content #MediaList .media-item .options[data-is-youtube=true] .NoDownload {
  display: block;
}
#DivMain #DivMainCenterWrapper .ContextModul .Content #MediaList .media-item .options[data-is-youtube=false] .Download {
  display: block;
}
#DivMain #DivMainCenterWrapper .ContextModul .Content #MediaList .media-item .options[data-is-youtube=false] .NoDownload {
  display: none;
}
#DivMain #DivMainCenterWrapper .ContextModul .Content #MediaList .media-item .options[data-is-new=true] .Hide,
#DivMain #DivMainCenterWrapper .ContextModul .Content #MediaList .media-item .options[data-is-new=true] .Share,
#DivMain #DivMainCenterWrapper .ContextModul .Content #MediaList .media-item .options[data-is-new=true] .Download,
#DivMain #DivMainCenterWrapper .ContextModul .Content #MediaList .media-item .options[data-is-new=true] .LightBox {
  display: none;
}
#DivMain #DivMainCenterWrapper .ContextModul .Content #MediaList .media-item .options[data-is-new=true] .NoDownload,
#DivMain #DivMainCenterWrapper .ContextModul .Content #MediaList .media-item .options[data-is-new=true] .Delete {
  display: block;
}
#DivMain #DivMainCenterWrapper .ContextModul .Content #MediaList .media-item .options[data-is-hidden=true] .Hide,
#DivMain #DivMainCenterWrapper .ContextModul .Content #MediaList .media-item .options[data-is-hidden=true] .Download,
#DivMain #DivMainCenterWrapper .ContextModul .Content #MediaList .media-item .options[data-is-hidden=true] .Share,
#DivMain #DivMainCenterWrapper .ContextModul .Content #MediaList .media-item .options[data-is-hidden=true] .NoDownload,
#DivMain #DivMainCenterWrapper .ContextModul .Content #MediaList .media-item .options[data-is-hidden=true] .LightBox {
  display: none;
}
#DivMain #DivMainCenterWrapper .ContextModul .Content #MediaList .media-item .mass-processing-overlay {
  display: none;
}
#DivMain #DivMainCenterWrapper .ContextModul .Content #MediaList[data-is-mass-processing-activated=true] .media-item .mass-processing-overlay {
  /*background-color: rgba(0, 0, 0, 0.2);*/
  background-color: rgba(238, 238, 238, 0.5);
  border: 1px solid #ccc;
  box-sizing: border-box;
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
#DivMain #DivMainCenterWrapper .ContextModul .Content #MediaList[data-is-mass-processing-activated=true] .media-item .mass-processing-overlay:hover {
  /*background-color: rgba(204, 204, 204, 0.5);*/
  background-color: rgba(238, 238, 238, 0.6);
}
#DivMain #DivMainCenterWrapper .ContextModul .Content #MediaList[data-is-mass-processing-activated=true] .media-item .mass-processing-overlay label {
  cursor: pointer;
  display: block;
  height: 100%;
  width: 100%;
  /*&:hover input ~ .checkmark {
                                        background-color: #ccc;
                                    }*/
}
#DivMain #DivMainCenterWrapper .ContextModul .Content #MediaList[data-is-mass-processing-activated=true] .media-item .mass-processing-overlay label input:checked ~ .checkmark {
  background-color: #65ac1e;
}
#DivMain #DivMainCenterWrapper .ContextModul .Content #MediaList[data-is-mass-processing-activated=true] .media-item .mass-processing-overlay label input:checked ~ .checkmark:after {
  display: block;
}
#DivMain #DivMainCenterWrapper .ContextModul .Content #MediaList[data-is-mass-processing-activated=true] .media-item .mass-processing-overlay label input[type=checkbox] {
  position: absolute;
  opacity: 0;
}
#DivMain #DivMainCenterWrapper .ContextModul .Content #MediaList[data-is-mass-processing-activated=true] .media-item .mass-processing-overlay label .checkmark {
  border-bottom: 1px solid #ccc;
  border-left: 1px solid #ccc;
  position: absolute;
  top: 0;
  right: 0;
  height: 25px;
  width: 25px;
  background-color: #ffffff;
  opacity: 0.8;
}
#DivMain #DivMainCenterWrapper .ContextModul .Content #MediaList[data-is-mass-processing-activated=true] .media-item .mass-processing-overlay label .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
#DivMain #DivMainCenterWrapper #Registration {
  position: relative;
}
#DivMain #DivMainCenterWrapper #Registration.ContextModul .Content {
  height: auto;
  position: static;
}
#DivMain #DivMainCenterWrapper #Registration.ContextModul .Content .ContentInner.FormContent {
  display: none;
}
#DivMain #DivMainCenterWrapper #Registration.ContextModul .Content .ContentInner input[type=checkbox][disabled] {
  opacity: 1;
}
#DivMain #DivMainCenterWrapper #Registration.ContextModul .Content .ContentInner label.intern,
#DivMain #DivMainCenterWrapper #Registration.ContextModul .Content .ContentInner label.extern {
  display: block;
  float: left;
  padding-top: 2px;
  width: 40px;
}
#DivMain #DivMainCenterWrapper #Registration.ContextModul .Content .ContentInner .FormRowCheckboxes {
  width: 500px !important;
}
#DivMain #DivMainCenterWrapper #Registration.ContextModul .Content .ContentInner .DivSpacer {
  padding-top: 15px;
}
#DivMain #DivMainCenterWrapper #Registration.ContextModul .Content .ContentInner .FormRow {
  zoom: 1;
  margin-top: 5px;
  margin-bottom: 5px;
  width: 375px;
}
#DivMain #DivMainCenterWrapper #Registration.ContextModul .Content .ContentInner .FormRow:before,
#DivMain #DivMainCenterWrapper #Registration.ContextModul .Content .ContentInner .FormRow:after {
  content: "";
  display: table;
}
#DivMain #DivMainCenterWrapper #Registration.ContextModul .Content .ContentInner .FormRow:after {
  clear: both;
}
#DivMain #DivMainCenterWrapper #Registration.ContextModul .Content .ContentInner .FormRow .logindata {
  float: left;
}
#DivMain #DivMainCenterWrapper #Registration.ContextModul .Content .ContentInner .FormRow input {
  padding: 2px;
}
#DivMain #DivMainCenterWrapper #Registration.ContextModul .Content .ContentInner .FormRow .label {
  display: block;
  float: left;
  min-width: 125px;
  padding-top: 5px;
  padding-right: 10px;
}
#DivMain #DivMainCenterWrapper #Registration.ContextModul .Content .ContentInner .FormRow .cb-alignment {
  float: left;
  width: 150px;
}
#DivMain #DivMainCenterWrapper #Registration.ContextModul .Content .ContentInner .FormRow .pflicht {
  display: block;
  text-align: right;
  margin-right: 10px;
}
#DivMain #DivMainCenterWrapper #Registration.ContextModul .Content .ContentInner .FormRow .dropdown {
  float: left;
  width: 100px;
}
#DivMain #DivMainCenterWrapper #Registration.ContextModul .Content .ContentInner .FormRow .textbox {
  width: 230px;
}
#DivMain #DivMainCenterWrapper #Registration.ContextModul .Content .ContentInner .FormRow .dropdown-country {
  width: 236px;
}
#DivMain #DivMainCenterWrapper #Registration.ContextModul .Content .ContentInner .FormRow .responsibles {
  width: 236px;
}
#DivMain #DivMainCenterWrapper #Registration.ContextModul .Content .ContentInner .FormPrivacy {
  margin-top: 5px;
  margin-bottom: 5px;
}
#DivMain #DivMainCenterWrapper #Registration.ContextModul .Content .ContentInner .FormPrivacy .k-invalid-msg {
  background-color: #fff;
  border: none;
  box-shadow: none;
  position: absolute;
  margin-top: 20px !important;
  left: 198px;
}
#DivMain #DivMainCenterWrapper #Registration.ContextModul .Content .ContentInner .FormPrivacy .k-invalid-msg .k-warning {
  display: none;
}
#DivMain #DivMainCenterWrapper #Registration.ContextModul .Content .ContentInner .FormInfo {
  margin-top: 15px;
}
#DivMain #DivMainCenterWrapper #Registration.ContextModul .Content .ContentInner .FormButton {
  margin-top: 30px;
}
.search-box-fieldset,
.fieldset {
  border: none;
  padding: 0;
  margin: 0;
  /*&[data-media-type=advertisingmaterial] {
        .advertisingmaterial {
            display: block !important;
        }
    }*/
}
.search-box-fieldset legend,
.fieldset legend {
  display: none;
}
.search-box-fieldset .hide,
.fieldset .hide {
  display: none !important;
}
.search-box-fieldset[data-media-type=Picture] .Picture,
.fieldset[data-media-type=Picture] .Picture {
  display: block !important;
}
.search-box-fieldset[data-media-type=Picture] .Picture .eppo-code-label,
.fieldset[data-media-type=Picture] .Picture .eppo-code-label {
  width: 190px;
}
.search-box-fieldset[data-media-type=Picture] .Picture .eppo-code-label .hover,
.fieldset[data-media-type=Picture] .Picture .eppo-code-label .hover {
  cursor: pointer;
  color: #333;
  font-weight: bold;
}
.search-box-fieldset[data-media-type=Illustration] .Illustration,
.fieldset[data-media-type=Illustration] .Illustration {
  display: block !important;
}
.search-box-fieldset[data-media-type=AudioVideo] .AudioVideo,
.fieldset[data-media-type=AudioVideo] .AudioVideo {
  display: block !important;
}
.search-box-fieldset[data-media-type=OpenFile] .OpenFile,
.fieldset[data-media-type=OpenFile] .OpenFile {
  display: block !important;
}
.search-box-fieldset[data-media-type=PDF] .PDF,
.fieldset[data-media-type=PDF] .PDF {
  display: block !important;
}
.search-box-fieldset .drop-down-field,
.fieldset .drop-down-field {
  width: 205px;
  margin-bottom: 10px;
}
.search-box-fieldset .drop-down-field > p,
.fieldset .drop-down-field > p {
  margin: 0 0 2px 0;
}
.search-box-fieldset .drop-down-field .k-dropdown,
.fieldset .drop-down-field .k-dropdown,
.search-box-fieldset .drop-down-field .k-combobox,
.fieldset .drop-down-field .k-combobox {
  width: 100%;
}
.search-box-fieldset .field,
.fieldset .field {
  width: 205px;
  margin-bottom: 10px;
}
.search-box-fieldset .field > *,
.fieldset .field > * {
  width: 100%;
}
.search-box-fieldset .field > input[type=text],
.fieldset .field > input[type=text] {
  width: 199px;
}
.search-box-fieldset .checkbox,
.fieldset .checkbox {
  zoom: 1;
  display: block;
}
.search-box-fieldset .checkbox:before,
.fieldset .checkbox:before,
.search-box-fieldset .checkbox:after,
.fieldset .checkbox:after {
  content: "";
  display: table;
}
.search-box-fieldset .checkbox:after,
.fieldset .checkbox:after {
  clear: both;
}
.search-box-fieldset .checkbox input,
.fieldset .checkbox input {
  float: left;
  width: auto;
  margin: 0;
}
.search-box-fieldset .checkbox span,
.fieldset .checkbox span {
  float: left;
  width: auto;
  margin-top: -1px;
  padding-left: 10px;
}
.search-box-fieldset .bas-filter,
.fieldset .bas-filter {
  zoom: 1;
  margin-bottom: 10px;
}
.search-box-fieldset .bas-filter:before,
.fieldset .bas-filter:before,
.search-box-fieldset .bas-filter:after,
.fieldset .bas-filter:after {
  content: "";
  display: table;
}
.search-box-fieldset .bas-filter:after,
.fieldset .bas-filter:after {
  clear: both;
}
.search-box-fieldset .bas-filter p,
.fieldset .bas-filter p {
  margin: 0 0 5px 0;
}
.search-box-fieldset .bas-filter .box1,
.fieldset .bas-filter .box1 {
  float: left;
  width: 40px;
  text-align: center;
  padding: 0;
  border-radius: 0;
  margin-right: 5px;
  height: 20px;
  border-color: #b2b2b2;
}
.search-box-fieldset .bas-filter .box2,
.fieldset .bas-filter .box2 {
  border-color: #b2b2b2;
  float: left;
  width: 35px;
  text-align: center;
  padding: 0;
  border-radius: 0;
  margin-right: 10px;
  height: 20px;
}
.search-box-fieldset .bas-filter .k-dropdown,
.fieldset .bas-filter .k-dropdown {
  float: left;
  width: 115px;
}
.search-box-fieldset .buttons-wrapper,
.fieldset .buttons-wrapper {
  zoom: 1;
}
.search-box-fieldset .buttons-wrapper:before,
.fieldset .buttons-wrapper:before,
.search-box-fieldset .buttons-wrapper:after,
.fieldset .buttons-wrapper:after {
  content: "";
  display: table;
}
.search-box-fieldset .buttons-wrapper:after,
.fieldset .buttons-wrapper:after {
  clear: both;
}
.search-box-fieldset .buttons-wrapper .advertisingmaterial,
.fieldset .buttons-wrapper .advertisingmaterial {
  float: left;
}
.search-box-fieldset .buttons-wrapper a,
.fieldset .buttons-wrapper a {
  float: right;
  margin-right: 12px;
}
#DivMain #DivMainCenterWrapper #Statistic {
  height: auto;
}
#DivMain #DivMainCenterWrapper #Statistic .content-row {
  zoom: 1;
}
#DivMain #DivMainCenterWrapper #Statistic .content-row:before,
#DivMain #DivMainCenterWrapper #Statistic .content-row:after {
  content: "";
  display: table;
}
#DivMain #DivMainCenterWrapper #Statistic .content-row:after {
  clear: both;
}
#DivMain #DivMainCenterWrapper #Statistic .content-row .content-cell {
  float: left;
}
#DivMain #DivMainCenterWrapper #Statistic .content-row .content-cell.content-cell-type p {
  margin-top: 15px;
}
#DivMain #DivMainCenterWrapper #Statistic .content-row .content-cell.width33 {
  width: 33%;
}
#DivMain #DivMainCenterWrapper #Statistic .content-row .content-cell.width50 {
  width: 50%;
}
#DivMain #DivMainCenterWrapper #Statistic .content-row .link-button {
  margin: 15px 0 10px;
}
#DivMain #DivMainCenterWrapper #Statistic #DownloadStatisticGrid,
#DivMain #DivMainCenterWrapper #Statistic #SearchStatisticGrid {
  margin-top: 10px;
}
#DivMain #DivMainCenterWrapper #Statistic #DownloadStatisticGrid .k-grid-header tr th,
#DivMain #DivMainCenterWrapper #Statistic #SearchStatisticGrid .k-grid-header tr th {
  font-size: 11px;
  font-weight: bold;
}
#DivMain #DivMainCenterWrapper #Statistic #DownloadStatisticGrid .k-grid-content tr td,
#DivMain #DivMainCenterWrapper #Statistic #SearchStatisticGrid .k-grid-content tr td,
#DivMain #DivMainCenterWrapper #Statistic #DownloadStatisticGrid .k-grid-content-locked tr td,
#DivMain #DivMainCenterWrapper #Statistic #SearchStatisticGrid .k-grid-content-locked tr td {
  font-size: 11px;
}
#DivMain #DivMainCenterWrapper #Statistic #DownloadStatisticGrid .k-grid-footer tr td,
#DivMain #DivMainCenterWrapper #Statistic #SearchStatisticGrid .k-grid-footer tr td {
  background-color: #f3f3f4;
  font-size: 11px;
  font-weight: bold;
}
.nav-creator-wrapper .horizontal-radio-list {
  zoom: 1;
  height: 50px;
}
.nav-creator-wrapper .horizontal-radio-list:before,
.nav-creator-wrapper .horizontal-radio-list:after {
  content: "";
  display: table;
}
.nav-creator-wrapper .horizontal-radio-list:after {
  clear: both;
}
.nav-creator-wrapper .horizontal-radio-list label {
  zoom: 1;
  margin-right: 15px;
  display: inline-block;
  float: left;
  margin-top: 12px;
}
.nav-creator-wrapper .horizontal-radio-list label:before,
.nav-creator-wrapper .horizontal-radio-list label:after {
  content: "";
  display: table;
}
.nav-creator-wrapper .horizontal-radio-list label:after {
  clear: both;
}
.nav-creator-wrapper .horizontal-radio-list label input {
  float: left;
}
.nav-creator-wrapper .horizontal-radio-list label span {
  float: left;
  margin-top: 2px;
  margin-left: 5px;
}
.nav-creator-wrapper .nav-creator-list table {
  font-size: 16px;
}
.nav-creator-wrapper .nav-creator-list table td {
  width: 150px;
}
#DivMain #DivMainCenterWrapper .ContextModul #UserRegistration {
  height: auto;
}
#DivMain #DivMainCenterWrapper .ContextModul #UserRegistration .content-row {
  zoom: 1;
}
#DivMain #DivMainCenterWrapper .ContextModul #UserRegistration .content-row:before,
#DivMain #DivMainCenterWrapper .ContextModul #UserRegistration .content-row:after {
  content: "";
  display: table;
}
#DivMain #DivMainCenterWrapper .ContextModul #UserRegistration .content-row:after {
  clear: both;
}
#DivMain #DivMainCenterWrapper .ContextModul #UserRegistration .content-row .left {
  float: left;
}
#DivMain #DivMainCenterWrapper .ContextModul #UserRegistration .content-row .right {
  float: right;
}
#DivMain #DivMainCenterWrapper .ContextModul #UserRegistration .content-row .content-cell-left {
  float: left;
  text-align: left;
  width: 50%;
}
#DivMain #DivMainCenterWrapper .ContextModul #UserRegistration .content-row .content-cell-left .k-widget {
  text-align: left;
}
#DivMain #DivMainCenterWrapper .ContextModul #UserRegistration .content-row .content-cell-left .k-datepicker {
  width: 125px;
}
#DivMain #DivMainCenterWrapper .ContextModul #UserRegistration .content-row .content-cell-right {
  float: right;
  text-align: right;
  width: 50%;
}
#DivMain #DivMainCenterWrapper .ContextModul #UserRegistration .content-row .content-cell-right .k-widget {
  text-align: left;
}
#DivMain #DivMainCenterWrapper .ContextModul #UserRegistration .content-row .applicant-selector .k-dropdown {
  width: 75px;
}
#DivMain #DivMainCenterWrapper .ContextModul #UserRegistration .footer-row {
  zoom: 1;
}
#DivMain #DivMainCenterWrapper .ContextModul #UserRegistration .footer-row:before,
#DivMain #DivMainCenterWrapper .ContextModul #UserRegistration .footer-row:after {
  content: "";
  display: table;
}
#DivMain #DivMainCenterWrapper .ContextModul #UserRegistration .footer-row:after {
  clear: both;
}
#DivMain #DivMainCenterWrapper .ContextModul #UserRegistration .footer-row .footer-cell {
  float: left;
  /*width: 25%;*/
}
#DivMain #DivMainCenterWrapper .ContextModul #UserRegistration .footer-row .footer-cell.footer-cell-left {
  text-align: left;
}
#DivMain #DivMainCenterWrapper .ContextModul #UserRegistration .footer-row .footer-cell.footer-cell-mid {
  text-align: center;
}
#DivMain #DivMainCenterWrapper .ContextModul #UserRegistration .footer-row .footer-cell.footer-cell-right {
  text-align: right;
}
#DivMain #DivMainCenterWrapper .ContextModul #UserRegistration #UserRegistrationGrid {
  margin-top: 10px;
  margin-bottom: 10px;
}
#DivMain #DivMainCenterWrapper .ContextModul #UserRegistration #UserRegistrationGrid .userregistration-grid-header {
  font-size: 11px;
  font-weight: bold;
}
#DivMain #DivMainCenterWrapper .ContextModul #UserRegistration #UserRegistrationGrid .k-state-selected {
  background-color: #84BD4B;
}
#DivMain #DivMainCenterWrapper .ContextModul #UserRegistration #UserRegistrationGrid .userregistration-grid-row {
  font-size: 11px;
}
#DivMain #DivMainCenterWrapper .ContextModul #UserRegistration #UserRegistrationGrid td {
  font-size: 12px;
}
#UserRegistrationWindow {
  /*.BASFContactExtern {
        display: none;
    }*/
}
#UserRegistrationWindow .table-form {
  width: 100%;
}
#UserRegistrationWindow input {
  padding: 2px;
}
#UserRegistrationWindow input[type=text],
#UserRegistrationWindow input[type=email],
#UserRegistrationWindow input[type=password] {
  width: 149px;
}
#UserRegistrationWindow .k-dropdown {
  width: 155px;
}
#UserRegistrationWindow #FromDatePicker,
#UserRegistrationWindow #ToDatePicker {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  height: 24px;
}
#UserRegistrationWindow .k-invalid-msg {
  color: #bb2222;
  position: absolute;
  background-color: #fff;
  border: none;
  box-shadow: none;
  margin-top: 2px;
}
#UserRegistrationWindow .k-invalid-msg .k-warning {
  display: none;
}
#UserRegistrationWindow .window-cell {
  zoom: 1;
}
#UserRegistrationWindow .window-cell:before,
#UserRegistrationWindow .window-cell:after {
  content: "";
  display: table;
}
#UserRegistrationWindow .window-cell:after {
  clear: both;
}
#UserRegistrationWindow .window-cell .window-cell-left {
  float: left;
  width: 50%;
}
#UserRegistrationWindow .window-cell .window-cell-left .left-content {
  text-align: left;
}
#UserRegistrationWindow .window-cell .window-cell-right {
  float: left;
  width: 50%;
}
#UserRegistrationWindow .window-cell .window-cell-right .left-content {
  text-align: left;
}
#UserRegistrationWindow .window-cell .k-datepicker {
  width: 125px;
}
#UserRegistrationWindow .window-row {
  zoom: 1;
}
#UserRegistrationWindow .window-row:before,
#UserRegistrationWindow .window-row:after {
  content: "";
  display: table;
}
#UserRegistrationWindow .window-row:after {
  clear: both;
}
#UserRegistrationWindow .window-row .window-cell-left {
  float: left;
  text-align: left;
  width: 50%;
}
#UserRegistrationWindow .window-row .window-cell-right {
  float: left;
  text-align: right;
  width: 50%;
}
#LightBoxesOverviewWrapper {
  padding: 0 10px;
}
#LightBoxesOverviewWrapper .lightboxes-list-top-wrapper {
  width: 100%;
}
#LightBoxesOverviewWrapper .lightboxes-list-top-wrapper .back-button-wrapper {
  float: left;
  width: 80px;
  height: 40px;
  line-height: 40px;
}
#LightBoxesOverviewWrapper .lightboxes-list-top-wrapper .back-button-wrapper .back-button {
  margin: 12px 0 0 7px;
}
#LightBoxesOverviewWrapper .lightboxes-list-top-wrapper .lightboxes-list-pager {
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
  height: 40px;
  line-height: 40px;
  width: 365px;
  float: left;
  clear: none;
  padding-left: 80px !important;
}
#LightBoxesOverviewWrapper .lightboxes-list-top-wrapper .lightboxes-list-pager .k-pager-first,
#LightBoxesOverviewWrapper .lightboxes-list-top-wrapper .lightboxes-list-pager .k-pager-last,
#LightBoxesOverviewWrapper .lightboxes-list-top-wrapper .lightboxes-list-pager .k-pager-info {
  display: none;
}
#LightBoxesOverviewWrapper .lightboxes-list-top-wrapper .lightboxes-list-pager .k-pager-sizes {
  float: right;
  padding: 0;
}
#LightBoxesOverviewWrapper .lightboxes-list-top-wrapper .lightboxes-list-pager .k-pager-nav {
  border: none;
  line-height: 40px !important;
  background: none !important;
}
#LightBoxesOverviewWrapper .lightboxes-list-top-wrapper .lightboxes-list-pager .k-pager-nav .k-i-arrow-w {
  background: url(../App_Images/buttons/global/btArrowLeftWhite.jpg) no-repeat;
  width: 28px;
  height: 26px;
}
#LightBoxesOverviewWrapper .lightboxes-list-top-wrapper .lightboxes-list-pager .k-pager-nav .k-i-arrow-e {
  background: url(../App_Images/buttons/global/btArrowRightWhtie.jpg) no-repeat;
  width: 28px;
  height: 26px;
}
#LightBoxesOverviewWrapper .lightboxes-list-top-wrapper .lightboxes-list-pager .k-pager-input {
  color: #004a96;
  width: 101px;
  text-align: center;
  font-weight: bold;
}
#LightBoxesOverviewWrapper .lightboxes-list-top-wrapper .lightboxes-list-pager .k-pager-input .k-textbox {
  color: #004a96;
  border-radius: 0;
  text-align: right;
  border-color: #000;
}
#LightBoxesOverviewWrapper .lightboxes-grid {
  border: none;
  border-top: 1px solid #f1f1f1;
  padding-top: 12px;
  margin-top: 7px;
  margin-bottom: 20px;
}
#LightBoxesOverviewWrapper .lightboxes-grid input[type=checkbox] {
  position: relative;
  top: 1px;
}
#LightBoxesOverviewWrapper .lightboxes-grid .k-grid-header {
  background-color: #f1f1f1;
}
#LightBoxesOverviewWrapper .lightboxes-grid .k-grid-header th {
  border: none;
  color: #004a96;
  font-weight: bold;
  vertical-align: middle;
}
#LightBoxesOverviewWrapper .lightboxes-grid .k-grid-header th:first-child {
  padding: 0;
  text-align: center;
}
#LightBoxesOverviewWrapper .lightboxes-grid .k-grid-footer td {
  border: none;
  vertical-align: middle;
}
#LightBoxesOverviewWrapper .lightboxes-grid .k-grid-footer td:first-child {
  padding: 0;
  text-align: center;
}
#LightBoxesOverviewWrapper .lightboxes-grid .k-grid-footer td .link-button {
  margin-top: 4px;
}
#LightBoxesOverviewWrapper .lightboxes-grid tbody tr td {
  border: none;
  font-size: 12px;
  background-color: #fff;
  border-bottom: 1px solid #f1f1f1;
  vertical-align: middle;
  height: 32px;
  padding-top: 1px;
  padding-bottom: 0;
}
#LightBoxesOverviewWrapper .lightboxes-grid tbody tr td:first-child {
  padding: 0;
  text-align: center;
}
#LightBoxesOverviewWrapper .lightboxes-grid tbody tr td.col-name {
  color: #004a96;
}
#LightBoxesOverviewWrapper .lightboxes-grid tbody tr td.col-cmds {
  text-align: right;
  padding: 0;
}
#LightBoxesOverviewWrapper .lightboxes-grid tbody tr td.col-cmds .k-button {
  width: 20px;
  height: 20px;
  border: none;
  min-width: 0;
  border-radius: 0;
  color: transparent;
  text-indent: -10000%;
}
#LightBoxesOverviewWrapper .lightboxes-grid tbody tr td.col-cmds .k-button span {
  display: none;
}
#LightBoxesOverviewWrapper .lightboxes-grid tbody tr td.col-cmds .k-grid-edit {
  background: url(/App_Images/buttons/icon-edit.png) no-repeat;
}
#LightBoxesOverviewWrapper .lightboxes-grid tbody tr td.col-cmds .k-grid-delete,
#LightBoxesOverviewWrapper .lightboxes-grid tbody tr td.col-cmds .k-grid-custom {
  background: url(/App_Images/buttons/icon-delete.png) no-repeat;
}
#LightBoxesOverviewWrapper .lightboxes-grid tbody tr td.col-cmds .k-grid-update {
  background: url(/App_Images/buttons/icon-save_green.png) no-repeat;
}
#LightBoxesOverviewWrapper .lightboxes-grid tbody tr td.col-cmds .k-grid-cancel {
  background: url(/App_Images/buttons/icon-abbort_grey.png) no-repeat;
}
#LightBoxesOverviewWrapper .lightboxes-grid tbody tr td input.k-input {
  border-radius: 0;
  border: 1px solid #004a96;
  color: #000;
}
#LightBoxesOverviewWrapper .create-new-lightbox {
  padding-bottom: 20px;
  padding-left: 5px;
}
#LightBoxesOverviewWrapper .create-new-lightbox fieldset {
  zoom: 1;
}
#LightBoxesOverviewWrapper .create-new-lightbox fieldset:before,
#LightBoxesOverviewWrapper .create-new-lightbox fieldset:after {
  content: "";
  display: table;
}
#LightBoxesOverviewWrapper .create-new-lightbox fieldset:after {
  clear: both;
}
#LightBoxesOverviewWrapper .create-new-lightbox fieldset input {
  float: left;
  margin-right: 20px;
  border: 1px solid #004a96;
  width: 230px;
}
#LightBoxesOverviewWrapper .create-new-lightbox fieldset button {
  float: left;
}
#LightBoxDetailWrapper {
  padding: 0 10px;
}
#LightBoxDetailWrapper .bg-green {
  background-color: rgba(101, 172, 30, 0.5) !important;
}
#LightBoxDetailWrapper .bg-red {
  background-color: rgba(255, 0, 0, 0.5) !important;
}
#LightBoxDetailWrapper .bg-yellow {
  background-color: rgba(255, 216, 0, 0.5) !important;
}
#LightBoxDetailWrapper .lightbox-detail-top-wrapper {
  width: 100%;
}
#LightBoxDetailWrapper .lightbox-detail-top-wrapper .back-button-wrapper {
  float: left;
  width: 80px;
  height: 40px;
  line-height: 40px;
}
#LightBoxDetailWrapper .lightbox-detail-top-wrapper .back-button-wrapper .back-button {
  margin: 12px 0 0 7px;
}
#LightBoxDetailWrapper .lightbox-detail-top-wrapper .lightbox-detail-pager {
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
  height: 40px;
  line-height: 40px;
  width: 365px;
  float: left;
  clear: none;
  padding-left: 80px !important;
}
#LightBoxDetailWrapper .lightbox-detail-top-wrapper .lightbox-detail-pager .k-pager-first,
#LightBoxDetailWrapper .lightbox-detail-top-wrapper .lightbox-detail-pager .k-pager-last,
#LightBoxDetailWrapper .lightbox-detail-top-wrapper .lightbox-detail-pager .k-pager-info {
  display: none;
}
#LightBoxDetailWrapper .lightbox-detail-top-wrapper .lightbox-detail-pager .k-pager-sizes {
  float: right;
  padding: 0;
}
#LightBoxDetailWrapper .lightbox-detail-top-wrapper .lightbox-detail-pager .k-pager-nav {
  border: none;
  line-height: 40px !important;
  background: none !important;
}
#LightBoxDetailWrapper .lightbox-detail-top-wrapper .lightbox-detail-pager .k-pager-nav .k-i-arrow-w {
  background: url(../App_Images/buttons/global/btArrowLeftWhite.jpg) no-repeat;
  width: 28px;
  height: 26px;
}
#LightBoxDetailWrapper .lightbox-detail-top-wrapper .lightbox-detail-pager .k-pager-nav .k-i-arrow-e {
  background: url(../App_Images/buttons/global/btArrowRightWhtie.jpg) no-repeat;
  width: 28px;
  height: 26px;
}
#LightBoxDetailWrapper .lightbox-detail-top-wrapper .lightbox-detail-pager .k-pager-input {
  color: #004a96;
  width: 101px;
  text-align: center;
  font-weight: bold;
}
#LightBoxDetailWrapper .lightbox-detail-top-wrapper .lightbox-detail-pager .k-pager-input .k-textbox {
  color: #004a96;
  border-radius: 0;
  text-align: right;
  border-color: #000;
}
#LightBoxDetailWrapper .lightbox-detail-grid {
  border: none;
  border-top: 1px solid #f1f1f1;
  padding-top: 12px;
  margin-top: 7px;
  margin-bottom: 20px;
}
#LightBoxDetailWrapper .lightbox-detail-grid table {
  table-layout: fixed;
}
#LightBoxDetailWrapper .lightbox-detail-grid table .Preview {
  cursor: pointer;
}
#LightBoxDetailWrapper .lightbox-detail-grid table input[type=checkbox] {
  position: relative;
  top: 1px;
}
#LightBoxDetailWrapper .lightbox-detail-grid table .k-grid-header {
  background-color: #f1f1f1;
}
#LightBoxDetailWrapper .lightbox-detail-grid table .k-grid-header th {
  border: none;
  color: #004a96;
  font-weight: bold;
  vertical-align: middle;
  padding: 0;
  height: 22px;
}
#LightBoxDetailWrapper .lightbox-detail-grid table .k-grid-header th:first-child {
  padding: 0;
  text-align: center;
}
#LightBoxDetailWrapper .lightbox-detail-grid table .k-grid-header th.col-image {
  padding-left: 3px;
  padding-right: 10px;
  padding-top: 3px;
}
#LightBoxDetailWrapper .lightbox-detail-grid table .k-grid-footer tr td {
  border: none;
  vertical-align: middle;
}
#LightBoxDetailWrapper .lightbox-detail-grid table .k-grid-footer tr td:first-child {
  padding: 0;
  text-align: center;
}
#LightBoxDetailWrapper .lightbox-detail-grid table tbody tr td {
  border: none;
  font-size: 12px;
  background-color: #fff;
  border-bottom: 1px solid #f1f1f1;
  vertical-align: middle;
  padding: 1px 5px;
  height: 60px;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}
#LightBoxDetailWrapper .lightbox-detail-grid table tbody tr td.col-image {
  padding-left: 3px;
  padding-right: 10px;
}
#LightBoxDetailWrapper .lightbox-detail-grid table tbody tr td.col-image img {
  width: 68px;
  height: 51px;
  border: none;
  display: block;
}
#LightBoxDetailWrapper .lightbox-detail-grid table tbody tr td.col-image span[data-type="3"] {
  position: relative;
  width: 68px;
  height: 51px;
  display: inline-block;
}
#LightBoxDetailWrapper .lightbox-detail-grid table tbody tr td.col-image span[data-type="3"] .video-play {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: url("/App_Images/buttons/icon-video-layer.png") no-repeat scroll center center transparent;
}
#LightBoxDetailWrapper .lightbox-detail-grid table tbody tr td:first-child {
  padding: 0;
  text-align: center;
}
#LightBoxDetailWrapper .lightbox-detail-grid table tbody tr td.col-name {
  color: #004a96;
}
#LightBoxDetailWrapper .lightbox-detail-grid table tbody tr td.col-cmds {
  text-align: right;
  padding: 0;
}
#LightBoxDetailWrapper .lightbox-detail-grid table tbody tr td.col-cmds .k-button {
  width: 20px;
  height: 20px;
  border: none;
  min-width: 0;
  border-radius: 0;
}
#LightBoxDetailWrapper .lightbox-detail-grid table tbody tr td.col-cmds .k-button:focus:active:not(.k-state-disabled):not([disabled]) {
  box-shadow: none;
}
#LightBoxDetailWrapper .lightbox-detail-grid table tbody tr td.col-cmds .k-button span {
  display: none;
}
#LightBoxDetailWrapper .lightbox-detail-grid table tbody tr td.col-cmds .k-grid-Download {
  background: url(/App_Images/buttons/icon-dl.png) no-repeat;
}
#LightBoxDetailWrapper .lightbox-detail-grid table tbody tr td.col-cmds .k-grid-Download[disabled] {
  opacity: 0.3;
}
#LightBoxDetailWrapper .lightbox-detail-grid table tbody tr td.col-cmds .k-grid-Share {
  background: url(/App_Images/buttons/icon-share.png) no-repeat;
}
#LightBoxDetailWrapper .lightbox-detail-grid table tbody tr td.col-cmds .k-grid-Detail {
  background: url(/App_Images/buttons/icon-info.png) no-repeat;
}
#LightBoxDetailWrapper .lightbox-detail-grid table tbody tr td.col-cmds .k-grid-Delete {
  background: url(/App_Images/buttons/icon-delete.png) no-repeat;
}
#LightBoxDetailWrapper .grid-actions {
  margin: 0 10px 30px 10px;
}
#LightBoxDetailWrapper .grid-actions .link-button {
  float: right;
}
#LightBoxDetailWrapper .grid-actions .link-button.grey {
  float: left;
}
#LightBoxDetailWrapper .grid-hint {
  font-size: 10px;
  margin: 0 10px;
}
#LightBoxDetailWrapper p {
  margin-left: 10px;
  margin-right: 10px;
}
#LightBoxDetailWrapper .send-mail {
  padding-bottom: 20px;
  margin: 0 10px;
}
#LightBoxDetailWrapper .send-mail table {
  width: 100%;
}
#LightBoxDetailWrapper .send-mail table tr td {
  width: 100%;
}
#LightBoxDetailWrapper .send-mail table tr td:first-child {
  padding-right: 20px;
  width: auto;
}
#LightBoxDetailWrapper .send-mail table tr td input,
#LightBoxDetailWrapper .send-mail table tr td textarea {
  width: 100%;
  min-width: 100%;
  max-width: 410px;
  border: 1px solid #b2b2b2;
  resize: none;
}
#LightBoxDetailWrapper .send-mail table tr td textarea {
  height: 50px;
  min-height: 50px;
  max-height: 50px;
  padding: 2px;
}
.LightBoxNameTextbox {
  width: 143px;
}
.VideoWrapper {
  zoom: 1;
}
.VideoWrapper:before,
.VideoWrapper:after {
  content: "";
  display: table;
}
.VideoWrapper:after {
  clear: both;
}
.VideoWrapper video {
  background-color: #f1f1f1;
}
.VideoWrapper .vjs-default-skin {
  color: #000000;
}
.VideoWrapper .vjs-default-skin .vjs-poster img {
  height: auto;
}
.VideoWrapper .vjs-default-skin .vjs-play-progress,
.VideoWrapper .vjs-default-skin .vjs-volume-level {
  background-color: #def1f8;
}
.VideoWrapper .vjs-default-skin .vjs-control-bar,
.VideoWrapper .vjs-default-skin .vjs-big-play-button {
  background: #fff;
}
.VideoWrapper .vjs-default-skin .vjs-slider {
  background: #f1f1f1;
}
.VideoWrapper .vjs-default-skin .vjs-big-play-button {
  background-image: url(/App_Images/buttons/video-layer.png);
  width: 40px;
  height: 40px;
  box-shadow: none;
  border-radius: 0;
  border: none;
  position: absolute;
  left: 50%;
  margin-left: -20px;
  top: 50%;
  margin-top: -20px;
}
.VideoWrapper .vjs-default-skin .vjs-big-play-button:before {
  content: "";
}
.VideoWrapper .vjs-default-skin .vjs-control-bar {
  display: block;
  visibility: visible !important;
  opacity: 1 !important;
  height: 20px;
  padding: 10px 0;
  bottom: -40px;
}
.VideoWrapper .vjs-default-skin .vjs-control-bar .vjs-live-controls {
  display: none;
}
.VideoWrapper .vjs-default-skin .vjs-control-bar .vjs-progress-control {
  position: relative;
  width: 260px;
  height: 10px;
  top: 5px;
}
.VideoWrapper .vjs-default-skin .vjs-control-bar .vjs-progress-control .vjs-play-progress {
  background-image: none;
}
.VideoWrapper .vjs-default-skin .vjs-control-bar .vjs-progress-control .vjs-seek-handle.vjs-slider-handle {
  display: none;
}
.VideoWrapper .vjs-default-skin .vjs-control-bar .vjs-play-control {
  background-image: url(/App_Images/buttons/icon-video-play.png);
  width: 20px;
  height: 20px;
  /*&.vjs-paused {
                    background-image: url(/App_Images/buttons/icon-video-play.png);
                }*/
}
.VideoWrapper .vjs-default-skin .vjs-control-bar .vjs-play-control:before {
  content: "";
}
.VideoWrapper .vjs-default-skin .vjs-control-bar .vjs-play-control.vjs-playing {
  background-image: url(/App_Images/buttons/icon-video-stop.png);
}
.VideoWrapper .vjs-default-skin .vjs-control-bar .vjs-volume-control {
  margin: 0 10px;
  height: 20px;
}
.VideoWrapper .vjs-default-skin .vjs-control-bar .vjs-volume-control .vjs-volume-bar {
  margin-top: 7px;
  height: 6px;
}
.VideoWrapper .vjs-default-skin .vjs-control-bar .vjs-volume-control .vjs-volume-bar .vjs-volume-level {
  background-image: none;
  height: 6px;
}
.VideoWrapper .vjs-default-skin .vjs-control-bar .vjs-volume-control .vjs-volume-bar .vjs-volume-handle {
  display: none;
}
.VideoWrapper .vjs-default-skin .vjs-control-bar .vjs-mute-control {
  width: 20px;
  height: 20px;
  background-color: #def1f8;
  color: #fff;
  font-size: 7px;
  text-shadow: 0 0 0 #def1f8;
}
.VideoWrapper .vjs-default-skin .vjs-control-bar .vjs-mute-control:before {
  text-shadow: 1px 1px 1px #def1f8;
}
.VideoWrapper .vjs-default-skin .vjs-control-bar .vjs-fullscreen-control {
  width: 20px;
  height: 20px;
  text-shadow: none;
  background-color: #def1f8;
  color: #fff;
  font-size: 7px;
}
.VideoWrapper .vjs-default-skin .vjs-control-bar .vjs-fullscreen-control:before {
  text-shadow: 1px 1px 1px #def1f8;
}
.VideoWrapper .vjs-default-skin .vjs-control-bar .vjs-current-time,
.VideoWrapper .vjs-default-skin .vjs-control-bar .vjs-time-divider,
.VideoWrapper .vjs-default-skin .vjs-control-bar .vjs-duration {
  font-size: 12px;
  height: 20px;
  line-height: 20px;
}
.VideoWrapper .vjs-default-skin.vjs-fullscreen .vjs-control-bar {
  bottom: 0;
  padding: 10px;
}
.VideoWrapper .vjs-default-skin.vjs-fullscreen .vjs-control-bar .vjs-progress-control {
  width: 100%;
  margin-top: 10px;
  padding: 0 130px 0 135px;
  position: absolute;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
#MediaListWrapper {
  display: none;
}
#RootContent {
  display: none;
}
#DetailWrapper {
  display: none;
}
#DetailWrapper .notices strong {
  color: #333;
}
#DetailWrapper .notices strong.red {
  color: #bb2222;
}
#DetailWrapper a.k-disabled {
  opacity: 0.3 !important;
  cursor: default !important;
}
#DetailWrapper [data-is-youtube=true] {
  display: none;
}
#DetailWrapper .preview-image-wrapper {
  width: 270px;
  height: 200px;
  text-align: center;
  padding-bottom: 30px;
  position: relative;
  cursor: pointer;
}
#DetailWrapper .preview-image-wrapper[data-media-type="3"] .video-play-button {
  width: 100%;
  background: url(/App_Images/buttons/video-layer.png) no-repeat center center transparent;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
#DetailWrapper .preview-image-wrapper .preview-image {
  border: none;
  height: auto;
  width: auto;
  max-width: 270px;
  max-height: 200px;
}
#DetailWrapper p[data-hint] {
  display: none;
}
#DetailWrapper p[data-hint=True],
#DetailWrapper p[data-hint=true] {
  display: block;
}
#DetailWrapper .DropDownListSelector {
  width: 137px;
}
#DetailWrapper .box-dark-grey {
  background-color: #cccccc;
  padding: 5px;
}
#DetailWrapper .box-grey {
  background-color: #f1f1f1;
  padding: 5px;
  vertical-align: middle;
}
#DetailWrapper .box-grey td {
  padding: 3px 5px;
}
#DetailWrapper .download-wrapper-extended {
  margin-top: 10px;
}
#DetailWrapper .download-wrapper-extended[data-previewpdfexists=false] {
  display: none;
}
#DetailWrapper .download-wrapper-extended .btnPreviewPDF {
  background: url(/App_Images/buttons/PDF.png) no-repeat left center transparent;
  padding-top: 2px;
  padding-bottom: 1px;
  padding-left: 20px;
  color: #333;
}
#DetailWrapper .media-options {
  padding-left: 10px;
  line-height: 20px;
  border-bottom: 1px solid #f1f1f1;
}
#DetailWrapper .media-options .new-file-actions {
  zoom: 1;
  display: none;
}
#DetailWrapper .media-options .new-file-actions:before,
#DetailWrapper .media-options .new-file-actions:after {
  content: "";
  display: table;
}
#DetailWrapper .media-options .new-file-actions:after {
  clear: both;
}
#DetailWrapper .media-options .new-file-actions a {
  float: right;
}
#DetailWrapper .media-options .new-file-actions a:first-child {
  float: left;
}
#DetailWrapper .media-options[data-is-new=true] > p {
  display: none;
}
#DetailWrapper .media-options[data-is-new=true] .new-file-actions {
  display: block;
  margin: 10px 0;
}
#DetailWrapper .media-options p {
  margin: 6px 0;
}
#DetailWrapper .media-options .can-disable-media-item[data-is-hidden=false] .show {
  display: none;
}
#DetailWrapper .media-options .can-disable-media-item[data-is-hidden=true] .hide {
  display: none;
}
#DetailWrapper .media-history {
  padding-left: 10px;
}
#DetailWrapper .media-history p {
  margin: 6px 0;
}
#DetailWrapper table {
  border-spacing: 0px;
  width: 100%;
}
#DetailWrapper table thead tr td {
  padding-top: 5px;
  padding-bottom: 10px;
}
#DetailWrapper table thead tr td.right {
  text-align: right;
  zoom: 1;
}
#DetailWrapper table thead tr td.right:before,
#DetailWrapper table thead tr td.right:after {
  content: "";
  display: table;
}
#DetailWrapper table thead tr td.right:after {
  clear: both;
}
#DetailWrapper table thead tr td.right .pager {
  float: right;
}
#DetailWrapper table thead tr td.right .pager .k-disabled {
  opacity: 0.3 !important;
  cursor: default !important;
}
#DetailWrapper table thead tr td.right .pager .arrow-left {
  float: left;
  display: block;
  cursor: pointer;
  margin-right: 10px;
}
#DetailWrapper table thead tr td.right .pager .arrow-left .k-icon {
  background: url(/App_Images/buttons/btArrowLeftWhite.jpg) no-repeat;
  width: 24px;
  height: 22px;
}
#DetailWrapper table thead tr td.right .pager .arrow-left .k-icon:hover {
  opacity: 0.9;
}
#DetailWrapper table thead tr td.right .pager .arrow-right {
  float: left;
  display: block;
  cursor: pointer;
  margin-right: -5px;
}
#DetailWrapper table thead tr td.right .pager .arrow-right .k-icon {
  background: url(/App_Images/buttons/btArrowRightWhtie.jpg) no-repeat;
  width: 24px;
  height: 22px;
}
#DetailWrapper table thead tr td.right .pager .arrow-right .k-icon:hover {
  opacity: 0.9;
}
#DetailWrapper table tr.is-delete td {
  background-color: #c50022;
  color: #fff;
}
#DetailWrapper table tr td {
  height: 15px;
  padding: 3px 5px;
  width: auto;
  word-break: break-word;
}
#DetailWrapper table tr td strong {
  color: #004a96;
}
#DetailWrapper table tr td.table-left {
  padding: 0;
  width: 280px;
}
#DetailWrapper table tr td.table-right {
  padding: 0;
  width: 245px;
}
#DetailWrapper .media-detail-picture {
  -ms-word-break: break-all;
  word-break: break-all;
  table-layout: fixed;
}
#DetailWrapper .media-detail-picture tr td {
  max-width: 121px;
  width: 33%;
}
#DetailWrapper .media-detail-video {
  margin: 0;
}
#DetailWrapper .media-detail-50 tr td {
  width: 50%;
}
#DetailWrapper .media-detail-33 tr td {
  width: 33%;
}
#DetailWrapper .download-wrapper {
  zoom: 1;
  padding-bottom: 5px;
}
#DetailWrapper .download-wrapper:before,
#DetailWrapper .download-wrapper:after {
  content: "";
  display: table;
}
#DetailWrapper .download-wrapper:after {
  clear: both;
}
#DetailWrapper .download-wrapper .link-button {
  float: right;
  margin-top: 3px;
  margin-right: -3px;
}
#DetailWrapper .CountrySelector {
  width: 160px;
  margin-top: 5px;
  margin-bottom: 5px;
}
#DetailWrapper .LanguageSelector {
  width: 160px;
}
#DetailWrapper .MatchCodeText.k-autocomplete {
  width: 160px;
  border: none;
  border-radius: 0;
}
#DetailWrapper .MatchCodeText.k-autocomplete input.MatchCodeText {
  margin-bottom: 0;
  border: 1px solid #b2b2b2;
  border-radius: 0;
  width: 160px;
}
#DetailWrapper .btnSaveSearchKeyWords {
  margin: 10px 0;
}
#DetailWrapper .btnDeleteSearchKeyWords {
  display: none;
}
#DetailWrapper .NewCountry {
  display: none;
}
#DetailWrapper .can-edit-media-item {
  height: 22px;
}
#DetailWrapper .can-edit-media-item + span label {
  display: block;
  float: left;
  width: 60px;
  margin-top: 4px;
}
#DetailWrapper .can-edit-media-item + span input[type=text] {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  width: 100px;
}
#DetailWrapper .can-edit-media-item + span input[type=text] textarea {
  height: 100px;
  resize: none;
  width: 246px;
}
#DetailWrapper .can-edit-media-item + span input[type=text].edit-country {
  margin-bottom: 5px;
}
#DetailWrapper .can-edit-media-item + span .editor-cell {
  float: left;
  margin-bottom: 10px;
  width: 170px;
}
#DetailWrapper .can-edit-media-item + span .editor-cell div {
  clear: both;
}
#DetailWrapper .can-edit-media-item + span .editor-cell input[type=checkbox] {
  /*clear: both;*/
  float: left;
}
#DetailWrapper .can-edit-media-item + span .editor-cell label {
  display: block;
  float: none;
  margin-bottom: 3px;
  width: 100%;
}
#DetailWrapper .can-edit-media-item + span .editor-cell textarea {
  height: 100px;
  resize: none;
  width: 246px;
}
#DetailWrapper .can-edit-media-item + span .matchcode-left {
  float: left;
  width: 200px;
}
#DetailWrapper .can-edit-media-item + span .matchcode-left label {
  display: block;
  float: none;
  margin-bottom: 3px;
  width: auto;
}
#DetailWrapper .can-edit-media-item + span .matchcode-right {
  float: left;
  width: 313px;
}
#DetailWrapper .can-edit-media-item + span .matchcode-right label {
  display: block;
  float: none;
  margin-bottom: 3px;
  width: auto;
}
#DetailWrapper .can-edit-media-item + span .matchcode-right .k-grid td {
  font-size: 12px;
}
#DetailWrapper .can-edit-media-item + span .element-margin {
  margin-bottom: 5px;
}
#DetailWrapper .can-edit-media-item + span .SelectedMatchCodes {
  margin-bottom: 11px;
  width: 200px;
}
#DetailWrapper .can-edit-media-item.edit-confirm {
  width: 43px;
}
#DetailWrapper .can-edit-media-item .edit-button {
  background: url(/App_Images/buttons/icon-edit.png) no-repeat transparent;
  width: 20px;
  height: 20px;
  display: block;
  cursor: pointer;
}
#DetailWrapper .can-edit-media-item .btnSave {
  background: url("/App_Images/buttons/icon-save_green.png") no-repeat scroll 0 0 rgba(0, 0, 0, 0);
  width: 20px;
  height: 20px;
  display: block;
  cursor: pointer;
  float: left;
  margin-right: 3px;
}
#DetailWrapper .can-edit-media-item .btnCancel {
  background: url("/App_Images/buttons/icon-abbort_grey.png") no-repeat scroll 0 0 rgba(0, 0, 0, 0);
  width: 20px;
  height: 20px;
  display: block;
  cursor: pointer;
  float: left;
}
#DetailWrapper .first-column {
  float: left;
  width: 50%;
}
#DetailWrapper .second-column {
  float: right;
  width: 50%;
}
#DetailWrapper .can-edit-responsible {
  height: 22px;
}
#DetailWrapper .can-edit-responsible + span label {
  display: block;
  float: left;
  width: 60px;
  margin-top: 4px;
}
#DetailWrapper .can-edit-responsible + span input[type=text] {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  width: 100px;
}
#DetailWrapper .can-edit-responsible + span input[type=text].edit-country {
  margin-bottom: 5px;
}
#DetailWrapper .can-edit-responsible + span .editor-cell {
  float: left;
  margin-bottom: 10px;
}
#DetailWrapper .can-edit-responsible + span .editor-cell label {
  display: block;
  float: none;
  margin-bottom: 3px;
}
#DetailWrapper .can-edit-responsible + span .editor-cell textarea {
  height: 100px;
  resize: none;
  width: 246px;
}
#DetailWrapper .can-edit-responsible + span .matchcode-left {
  float: left;
  width: 200px;
}
#DetailWrapper .can-edit-responsible + span .matchcode-left label {
  display: block;
  float: none;
  margin-bottom: 3px;
  width: auto;
}
#DetailWrapper .can-edit-responsible + span .matchcode-right {
  float: left;
  width: 313px;
}
#DetailWrapper .can-edit-responsible + span .matchcode-right label {
  display: block;
  float: none;
  margin-bottom: 3px;
  width: auto;
}
#DetailWrapper .can-edit-responsible + span .matchcode-right .k-grid td {
  font-size: 12px;
}
#DetailWrapper .can-edit-responsible + span .element-margin {
  margin-bottom: 5px;
}
#DetailWrapper .can-edit-responsible + span .SelectedMatchCodes {
  margin-bottom: 11px;
  width: 200px;
}
#DetailWrapper .can-edit-responsible.edit-confirm {
  width: 43px;
}
#DetailWrapper .can-edit-responsible .edit-button {
  background: url(/App_Images/buttons/icon-edit.png) no-repeat transparent;
  width: 20px;
  height: 20px;
  display: block;
  cursor: pointer;
}
#DetailWrapper .can-edit-responsible .btnSave {
  background: url("/App_Images/buttons/icon-save_green.png") no-repeat scroll 0 0 rgba(0, 0, 0, 0);
  width: 20px;
  height: 20px;
  display: block;
  cursor: pointer;
  float: left;
  margin-right: 3px;
}
#DetailWrapper .can-edit-responsible .btnCancel {
  background: url("/App_Images/buttons/icon-abbort_grey.png") no-repeat scroll 0 0 rgba(0, 0, 0, 0);
  width: 20px;
  height: 20px;
  display: block;
  cursor: pointer;
  float: left;
}
#DetailWrapper .can-edit-licence-item {
  height: 22px;
}
#DetailWrapper .can-edit-licence-item + span label {
  display: block;
  float: left;
  width: 60px;
  margin-top: 4px;
}
#DetailWrapper .can-edit-licence-item + span input[type=text] {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  width: 100px;
}
#DetailWrapper .can-edit-licence-item + span input[type=text].edit-country {
  margin-bottom: 5px;
}
#DetailWrapper .can-edit-licence-item + span .editor-cell {
  float: left;
  margin-bottom: 10px;
}
#DetailWrapper .can-edit-licence-item + span .editor-cell label {
  display: block;
  float: none;
  margin-bottom: 3px;
}
#DetailWrapper .can-edit-licence-item + span .editor-cell textarea {
  height: 100px;
  resize: none;
  width: 246px;
}
#DetailWrapper .can-edit-licence-item + span .matchcode-left {
  float: left;
  width: 200px;
}
#DetailWrapper .can-edit-licence-item + span .matchcode-left label {
  display: block;
  float: none;
  margin-bottom: 3px;
  width: auto;
}
#DetailWrapper .can-edit-licence-item + span .matchcode-right {
  float: left;
  width: 313px;
}
#DetailWrapper .can-edit-licence-item + span .matchcode-right label {
  display: block;
  float: none;
  margin-bottom: 3px;
  width: auto;
}
#DetailWrapper .can-edit-licence-item + span .matchcode-right .k-grid td {
  font-size: 12px;
}
#DetailWrapper .can-edit-licence-item + span .element-margin {
  margin-bottom: 5px;
}
#DetailWrapper .can-edit-licence-item + span .SelectedMatchCodes {
  margin-bottom: 11px;
  width: 200px;
}
#DetailWrapper .can-edit-licence-item.edit-confirm {
  width: 43px;
}
#DetailWrapper .can-edit-licence-item .edit-button {
  background: url(/App_Images/buttons/icon-edit.png) no-repeat transparent;
  width: 20px;
  height: 20px;
  display: block;
  cursor: pointer;
}
#DetailWrapper .can-edit-licence-item .btnSave {
  background: url("/App_Images/buttons/icon-save_green.png") no-repeat scroll 0 0 rgba(0, 0, 0, 0);
  width: 20px;
  height: 20px;
  display: block;
  cursor: pointer;
  float: left;
  margin-right: 3px;
}
#DetailWrapper .can-edit-licence-item .btnCancel {
  background: url("/App_Images/buttons/icon-abbort_grey.png") no-repeat scroll 0 0 rgba(0, 0, 0, 0);
  width: 20px;
  height: 20px;
  display: block;
  cursor: pointer;
  float: left;
}
#DetailWrapper .VideoWrapper {
  /*display: none;*/
}
#DetailWrapper .VideoWrapper .vjs-current-time,
#DetailWrapper .VideoWrapper .vjs-time-divider,
#DetailWrapper .VideoWrapper .vjs-duration {
  display: none;
}
#DetailWrapper .VideoWrapper .vjs-progress-control {
  margin-left: 10px;
  width: 120px;
}
#DetailWrapper .VideoWrapper .vjs-has-started {
  height: 173px !important;
}
#DetailWrapper .VideoWrapper .vjs-has-started.vjs-fullscreen {
  height: 100% !important;
}
#DetailWrapper .VideoWrapper .vjs-fullscreen .vjs-current-time,
#DetailWrapper .VideoWrapper .vjs-fullscreen .vjs-time-divider,
#DetailWrapper .VideoWrapper .vjs-fullscreen .vjs-duration {
  display: block;
}
#DetailWrapper .VideoWrapper .vjs-fullscreen .vjs-control-bar {
  bottom: 0;
  padding: 10px;
}
#DetailWrapper .VideoWrapper .vjs-fullscreen .vjs-control-bar .vjs-progress-control {
  width: 100%;
  margin-top: 10px;
  padding: 0 130px 0 135px;
  position: absolute;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
#MassProcessingWrapper {
  display: none;
}
#MassProcessingWrapper .media-mass-processing-header {
  margin-bottom: 20px;
}
#MassProcessingWrapper .media-mass-processing-header .selected-media-items-wrapper {
  padding: 0;
}
#MassProcessingWrapper .media-mass-processing-header .selected-media-items-wrapper .selected-media-items {
  height: 50px;
  overflow-y: scroll;
  padding: 5px;
}
#MassProcessingWrapper .media-mass-processing-content tr:nth-child(2n) td:not([role=gridcell]) {
  padding-bottom: 20px;
}
#MassProcessingWrapper .media-mass-processing-content .action-wrapper {
  zoom: 1;
  margin-bottom: 10px;
}
#MassProcessingWrapper .media-mass-processing-content .action-wrapper:before,
#MassProcessingWrapper .media-mass-processing-content .action-wrapper:after {
  content: "";
  display: table;
}
#MassProcessingWrapper .media-mass-processing-content .action-wrapper:after {
  clear: both;
}
#MassProcessingWrapper .media-mass-processing-content .action-wrapper .k-dropdown {
  margin-top: 5px;
}
#MassProcessingWrapper .media-mass-processing-content .action-wrapper .txt-box {
  float: left;
  margin-right: 10px;
  width: 250px;
}
#MassProcessingWrapper .media-mass-processing-content .action-wrapper .txt-box + .link-button {
  /*display: block;*/
  /*float: left;*/
}
#MassProcessingWrapper .media-mass-processing-content .media-mass-processing-before-update-wrapper {
  margin-bottom: 20px;
}
#MassProcessingWrapper .media-mass-processing-content .media-mass-processing-before-update-wrapper,
#MassProcessingWrapper .media-mass-processing-content .media-mass-processing-after-update-wrapper {
  margin-top: 20px;
  padding: 0;
}
#MassProcessingWrapper .media-mass-processing-content .media-mass-processing-before-update-wrapper .media-mass-processing-before-update-items,
#MassProcessingWrapper .media-mass-processing-content .media-mass-processing-after-update-wrapper .media-mass-processing-before-update-items,
#MassProcessingWrapper .media-mass-processing-content .media-mass-processing-before-update-wrapper .media-mass-processing-after-update-items,
#MassProcessingWrapper .media-mass-processing-content .media-mass-processing-after-update-wrapper .media-mass-processing-after-update-items {
  height: 50px;
  overflow-y: scroll;
  padding: 5px;
}
#MassProcessingWrapper a.k-disabled {
  opacity: 0.3 !important;
  cursor: default !important;
}
#MassProcessingWrapper [data-is-youtube=true] {
  display: none;
}
#MassProcessingWrapper p[data-hint] {
  display: none;
}
#MassProcessingWrapper p[data-hint=True],
#MassProcessingWrapper p[data-hint=true] {
  display: block;
}
#MassProcessingWrapper .DropDownListSelector {
  width: 137px;
}
#MassProcessingWrapper .box-dark-grey {
  background-color: #cccccc;
  padding: 5px;
}
#MassProcessingWrapper .box-grey {
  background-color: #f1f1f1;
  padding: 5px;
  vertical-align: middle;
}
#MassProcessingWrapper .box-grey td {
  padding: 3px 5px;
}
#MassProcessingWrapper .download-wrapper-extended {
  margin-top: 10px;
}
#MassProcessingWrapper .download-wrapper-extended[data-previewpdfexists=false] {
  display: none;
}
#MassProcessingWrapper .download-wrapper-extended .btnPreviewPDF {
  background: url(/App_Images/buttons/PDF.png) no-repeat left center transparent;
  padding-top: 2px;
  padding-bottom: 1px;
  padding-left: 20px;
  color: #333;
}
#MassProcessingWrapper .media-options {
  padding-left: 10px;
  line-height: 20px;
  border-bottom: 1px solid #f1f1f1;
}
#MassProcessingWrapper .media-options .new-file-actions {
  zoom: 1;
  display: none;
}
#MassProcessingWrapper .media-options .new-file-actions:before,
#MassProcessingWrapper .media-options .new-file-actions:after {
  content: "";
  display: table;
}
#MassProcessingWrapper .media-options .new-file-actions:after {
  clear: both;
}
#MassProcessingWrapper .media-options .new-file-actions a {
  float: right;
}
#MassProcessingWrapper .media-options .new-file-actions a:first-child {
  float: left;
}
#MassProcessingWrapper .media-options[data-is-new=true] > p {
  display: none;
}
#MassProcessingWrapper .media-options[data-is-new=true] .new-file-actions {
  display: block;
  margin: 10px 0;
}
#MassProcessingWrapper .media-options p {
  margin: 6px 0;
}
#MassProcessingWrapper .media-options .can-disable-media-item[data-is-hidden=false] .show {
  display: none;
}
#MassProcessingWrapper .media-options .can-disable-media-item[data-is-hidden=true] .hide {
  display: none;
}
#MassProcessingWrapper .media-history {
  padding-left: 10px;
}
#MassProcessingWrapper .media-history p {
  margin: 6px 0;
}
#MassProcessingWrapper table {
  border-spacing: 0px;
  width: 100%;
}
#MassProcessingWrapper table thead tr td {
  padding-top: 5px;
  padding-bottom: 10px;
}
#MassProcessingWrapper table thead tr td.right {
  text-align: right;
  zoom: 1;
}
#MassProcessingWrapper table thead tr td.right:before,
#MassProcessingWrapper table thead tr td.right:after {
  content: "";
  display: table;
}
#MassProcessingWrapper table thead tr td.right:after {
  clear: both;
}
#MassProcessingWrapper table thead tr td.right .pager {
  float: right;
}
#MassProcessingWrapper table thead tr td.right .pager .k-disabled {
  opacity: 0.3 !important;
  cursor: default !important;
}
#MassProcessingWrapper table thead tr td.right .pager .arrow-left {
  float: left;
  display: block;
  cursor: pointer;
  margin-right: 10px;
}
#MassProcessingWrapper table thead tr td.right .pager .arrow-left .k-icon {
  background: url(/App_Images/buttons/btArrowLeftWhite.jpg) no-repeat;
  width: 24px;
  height: 22px;
}
#MassProcessingWrapper table thead tr td.right .pager .arrow-left .k-icon:hover {
  opacity: 0.9;
}
#MassProcessingWrapper table thead tr td.right .pager .arrow-right {
  float: left;
  display: block;
  cursor: pointer;
  margin-right: -5px;
}
#MassProcessingWrapper table thead tr td.right .pager .arrow-right .k-icon {
  background: url(/App_Images/buttons/btArrowRightWhtie.jpg) no-repeat;
  width: 24px;
  height: 22px;
}
#MassProcessingWrapper table thead tr td.right .pager .arrow-right .k-icon:hover {
  opacity: 0.9;
}
#MassProcessingWrapper table tr.is-new td {
  background-color: #65ac1e;
  color: #fff;
}
#MassProcessingWrapper table tr.is-delete td {
  background-color: #c50022;
  color: #fff;
}
#MassProcessingWrapper table tr td:not([role=gridcell]) {
  height: 15px;
  padding: 3px 5px;
  width: auto;
  word-break: break-word;
}
#MassProcessingWrapper table tr td:not([role=gridcell]) strong {
  color: #004a96;
}
#MassProcessingWrapper table tr td:not([role=gridcell]).table-left {
  padding: 0;
  width: 280px;
}
#MassProcessingWrapper table tr td:not([role=gridcell]).table-right {
  padding: 0;
  width: 245px;
}
#MassProcessingWrapper table tr td[role=gridcell] {
  font-size: 12px;
}
#MassProcessingWrapper .media-detail-picture {
  -ms-word-break: break-all;
  word-break: break-all;
  table-layout: fixed;
}
#MassProcessingWrapper .media-detail-picture tr td {
  max-width: 121px;
  width: 33%;
}
#MassProcessingWrapper .media-detail-video {
  margin: 0;
}
#MassProcessingWrapper .media-detail-50 tr td {
  width: 50%;
}
#MassProcessingWrapper .media-detail-33 tr td {
  width: 33%;
}
#MassProcessingWrapper .download-wrapper {
  zoom: 1;
  padding-bottom: 5px;
}
#MassProcessingWrapper .download-wrapper:before,
#MassProcessingWrapper .download-wrapper:after {
  content: "";
  display: table;
}
#MassProcessingWrapper .download-wrapper:after {
  clear: both;
}
#MassProcessingWrapper .download-wrapper .link-button {
  float: right;
  margin-top: 3px;
  margin-right: -3px;
}
#MassProcessingWrapper .CountrySelector {
  width: 160px;
  margin-top: 5px;
  margin-bottom: 5px;
}
#MassProcessingWrapper .LanguageSelector {
  width: 160px;
}
#MassProcessingWrapper .MatchCodeText.k-autocomplete {
  width: 160px;
  border: none;
  border-radius: 0;
}
#MassProcessingWrapper .MatchCodeText.k-autocomplete input.MatchCodeText {
  margin-bottom: 0;
  border: 1px solid #b2b2b2;
  border-radius: 0;
}
#MassProcessingWrapper .btnSaveSearchKeyWords {
  margin: 10px 0;
}
#MassProcessingWrapper .btnDeleteSearchKeyWords {
  display: none;
}
#MassProcessingWrapper .NewCountry {
  display: none;
}
#MassProcessingWrapper .can-edit-responsible {
  height: 22px;
}
#MassProcessingWrapper .can-edit-responsible + span label {
  display: block;
  float: left;
  width: 60px;
  margin-top: 4px;
}
#MassProcessingWrapper .can-edit-responsible + span input[type=text] {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  width: 100px;
}
#MassProcessingWrapper .can-edit-responsible + span input[type=text] textarea {
  height: 100px;
  resize: none;
  width: 246px;
}
#MassProcessingWrapper .can-edit-responsible + span input[type=text].edit-country {
  margin-bottom: 5px;
}
#MassProcessingWrapper .can-edit-responsible + span .editor-cell {
  float: left;
  margin-bottom: 10px;
  /*width: 170px;*/
}
#MassProcessingWrapper .can-edit-responsible + span .editor-cell div {
  clear: both;
}
#MassProcessingWrapper .can-edit-responsible + span .editor-cell input[type=checkbox] {
  /*clear: both;*/
  float: left;
}
#MassProcessingWrapper .can-edit-responsible + span .editor-cell label {
  display: block;
  float: none;
  margin-bottom: 3px;
  width: 100%;
}
#MassProcessingWrapper .can-edit-responsible + span .editor-cell textarea {
  height: 100px;
  resize: none;
  width: 246px;
}
#MassProcessingWrapper .can-edit-responsible + span .matchcode-left {
  float: left;
  width: 200px;
}
#MassProcessingWrapper .can-edit-responsible + span .matchcode-left label {
  display: block;
  float: none;
  margin-bottom: 3px;
  width: auto;
}
#MassProcessingWrapper .can-edit-responsible + span .matchcode-right {
  float: left;
  width: 313px;
}
#MassProcessingWrapper .can-edit-responsible + span .matchcode-right label {
  display: block;
  float: none;
  margin-bottom: 3px;
  width: auto;
}
#MassProcessingWrapper .can-edit-responsible + span .matchcode-right .k-grid td {
  font-size: 12px;
}
#MassProcessingWrapper .can-edit-responsible + span .element-margin {
  margin-bottom: 5px;
}
#MassProcessingWrapper .can-edit-responsible + span .SelectedMatchCodes {
  margin-bottom: 11px;
  width: 200px;
}
#MassProcessingWrapper .can-edit-responsible.edit-confirm {
  width: 43px;
}
#MassProcessingWrapper .can-edit-responsible .edit-button {
  background: url(/App_Images/buttons/icon-edit.png) no-repeat transparent;
  width: 20px;
  height: 20px;
  display: block;
  cursor: pointer;
}
#MassProcessingWrapper .can-edit-responsible .btnSave {
  background: url("/App_Images/buttons/icon-save_green.png") no-repeat scroll 0 0 rgba(0, 0, 0, 0);
  width: 20px;
  height: 20px;
  display: block;
  cursor: pointer;
  float: left;
  margin-right: 3px;
}
#MassProcessingWrapper .can-edit-responsible .btnCancel {
  background: url("/App_Images/buttons/icon-abbort_grey.png") no-repeat scroll 0 0 rgba(0, 0, 0, 0);
  width: 20px;
  height: 20px;
  display: block;
  cursor: pointer;
  float: left;
}
#MassProcessingWrapper .can-edit-media-item {
  height: 22px;
}
#MassProcessingWrapper .can-edit-media-item + span label {
  display: block;
  float: left;
  width: 60px;
  margin-top: 4px;
}
#MassProcessingWrapper .can-edit-media-item + span input[type=text] {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  width: 100px;
}
#MassProcessingWrapper .can-edit-media-item + span input[type=text] textarea {
  height: 100px;
  resize: none;
  width: 246px;
}
#MassProcessingWrapper .can-edit-media-item + span input[type=text].edit-country {
  margin-bottom: 5px;
}
#MassProcessingWrapper .can-edit-media-item + span .editor-cell {
  float: left;
  margin-bottom: 10px;
  /*width: 170px;*/
}
#MassProcessingWrapper .can-edit-media-item + span .editor-cell div {
  clear: both;
}
#MassProcessingWrapper .can-edit-media-item + span .editor-cell input[type=checkbox] {
  /*clear: both;*/
  float: left;
}
#MassProcessingWrapper .can-edit-media-item + span .editor-cell label {
  display: block;
  float: none;
  margin-bottom: 3px;
  width: 100%;
}
#MassProcessingWrapper .can-edit-media-item + span .editor-cell textarea {
  height: 100px;
  resize: none;
  width: 246px;
}
#MassProcessingWrapper .can-edit-media-item + span .matchcode-left {
  float: left;
  width: 200px;
}
#MassProcessingWrapper .can-edit-media-item + span .matchcode-left label {
  display: block;
  float: none;
  margin-bottom: 3px;
  width: auto;
}
#MassProcessingWrapper .can-edit-media-item + span .matchcode-right {
  float: left;
  width: 313px;
}
#MassProcessingWrapper .can-edit-media-item + span .matchcode-right label {
  display: block;
  float: none;
  margin-bottom: 3px;
  width: auto;
}
#MassProcessingWrapper .can-edit-media-item + span .matchcode-right .k-grid td {
  font-size: 12px;
}
#MassProcessingWrapper .can-edit-media-item + span .element-margin {
  margin-bottom: 5px;
}
#MassProcessingWrapper .can-edit-media-item + span .SelectedMatchCodes {
  margin-bottom: 11px;
  width: 200px;
}
#MassProcessingWrapper .can-edit-media-item.edit-confirm {
  width: 43px;
}
#MassProcessingWrapper .can-edit-media-item .edit-button {
  background: url(/App_Images/buttons/icon-edit.png) no-repeat transparent;
  width: 20px;
  height: 20px;
  display: block;
  cursor: pointer;
}
#MassProcessingWrapper .can-edit-media-item .btnSave {
  background: url("/App_Images/buttons/icon-save_green.png") no-repeat scroll 0 0 rgba(0, 0, 0, 0);
  width: 20px;
  height: 20px;
  display: block;
  cursor: pointer;
  float: left;
  margin-right: 3px;
}
#MassProcessingWrapper .can-edit-media-item .btnCancel {
  background: url("/App_Images/buttons/icon-abbort_grey.png") no-repeat scroll 0 0 rgba(0, 0, 0, 0);
  width: 20px;
  height: 20px;
  display: block;
  cursor: pointer;
  float: left;
}
#MassProcessingWrapper .first-column {
  float: left;
  width: 50%;
}
#MassProcessingWrapper .second-column {
  float: right;
  width: 50%;
}
#MassProcessingWrapper .can-edit-licence-item {
  height: 22px;
}
#MassProcessingWrapper .can-edit-licence-item + span label {
  display: block;
  float: left;
  width: 60px;
  margin-top: 4px;
}
#MassProcessingWrapper .can-edit-licence-item + span input[type=text] {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  width: 100px;
}
#MassProcessingWrapper .can-edit-licence-item + span input[type=text].edit-country {
  margin-bottom: 5px;
}
#MassProcessingWrapper .can-edit-licence-item + span .editor-cell {
  float: left;
  margin-bottom: 10px;
}
#MassProcessingWrapper .can-edit-licence-item + span .editor-cell label {
  display: block;
  float: none;
  margin-bottom: 3px;
}
#MassProcessingWrapper .can-edit-licence-item + span .editor-cell textarea {
  height: 100px;
  resize: none;
  width: 246px;
}
#MassProcessingWrapper .can-edit-licence-item + span .matchcode-left {
  float: left;
  width: 200px;
}
#MassProcessingWrapper .can-edit-licence-item + span .matchcode-left label {
  display: block;
  float: none;
  margin-bottom: 3px;
  width: auto;
}
#MassProcessingWrapper .can-edit-licence-item + span .matchcode-right {
  float: left;
  width: 313px;
}
#MassProcessingWrapper .can-edit-licence-item + span .matchcode-right label {
  display: block;
  float: none;
  margin-bottom: 3px;
  width: auto;
}
#MassProcessingWrapper .can-edit-licence-item + span .matchcode-right .k-grid td {
  font-size: 12px;
}
#MassProcessingWrapper .can-edit-licence-item + span .element-margin {
  margin-bottom: 5px;
}
#MassProcessingWrapper .can-edit-licence-item + span .SelectedMatchCodes {
  margin-bottom: 11px;
  width: 200px;
}
#MassProcessingWrapper .can-edit-licence-item.edit-confirm {
  width: 43px;
}
#MassProcessingWrapper .can-edit-licence-item .edit-button {
  background: url(/App_Images/buttons/icon-edit.png) no-repeat transparent;
  width: 20px;
  height: 20px;
  display: block;
  cursor: pointer;
}
#MassProcessingWrapper .can-edit-licence-item .btnSave {
  background: url("/App_Images/buttons/icon-save_green.png") no-repeat scroll 0 0 rgba(0, 0, 0, 0);
  width: 20px;
  height: 20px;
  display: block;
  cursor: pointer;
  float: left;
  margin-right: 3px;
}
#MassProcessingWrapper .can-edit-licence-item .btnCancel {
  background: url("/App_Images/buttons/icon-abbort_grey.png") no-repeat scroll 0 0 rgba(0, 0, 0, 0);
  width: 20px;
  height: 20px;
  display: block;
  cursor: pointer;
  float: left;
}
#MassProcessingWrapper .VideoWrapper {
  /*display: none;*/
}
#MassProcessingWrapper .VideoWrapper .vjs-current-time,
#MassProcessingWrapper .VideoWrapper .vjs-time-divider,
#MassProcessingWrapper .VideoWrapper .vjs-duration {
  display: none;
}
#MassProcessingWrapper .VideoWrapper .vjs-progress-control {
  margin-left: 10px;
  width: 120px;
}
#MassProcessingWrapper .VideoWrapper .vjs-has-started {
  height: 173px !important;
}
#MassProcessingWrapper .VideoWrapper .vjs-has-started.vjs-fullscreen {
  height: 100% !important;
}
#MassProcessingWrapper .VideoWrapper .vjs-fullscreen .vjs-current-time,
#MassProcessingWrapper .VideoWrapper .vjs-fullscreen .vjs-time-divider,
#MassProcessingWrapper .VideoWrapper .vjs-fullscreen .vjs-duration {
  display: block;
}
#MassProcessingWrapper .VideoWrapper .vjs-fullscreen .vjs-control-bar {
  bottom: 0;
  padding: 10px;
}
#MassProcessingWrapper .VideoWrapper .vjs-fullscreen .vjs-control-bar .vjs-progress-control {
  width: 100%;
  margin-top: 10px;
  padding: 0 130px 0 135px;
  position: absolute;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
#MediaMergeWrapper #MediaSelect .table-media-merge .k-combobox,
#MediaMergeWrapper #MediaSelect .table-media-merge .k-dropdown {
  width: 100%;
}
.k-animation-container #MediaSelector-list .media-dropdown-row {
  zoom: 1;
  cursor: pointer;
}
.k-animation-container #MediaSelector-list .media-dropdown-row:before,
.k-animation-container #MediaSelector-list .media-dropdown-row:after {
  content: "";
  display: table;
}
.k-animation-container #MediaSelector-list .media-dropdown-row:after {
  clear: both;
}
.k-animation-container #MediaSelector-list .media-dropdown-row.media-dropdown-header-row {
  background-color: #f1f1f1;
  cursor: default;
}
.k-animation-container #MediaSelector-list .media-dropdown-row.media-dropdown-header-row span {
  color: #004a96;
  font-weight: bold;
  padding: 5px;
}
.k-animation-container #MediaSelector-list .media-dropdown-row span {
  box-sizing: border-box;
  display: block;
  float: left;
  padding: 2px 5px;
  width: 33%;
}
.can-edit-addresses,
.can-mass-upload,
.can-view-activitylog,
.can-view-statistic,
.can-send-newsletter,
.can-edit-user,
.can-edit-nav,
.can-edit-content,
.can-disable-media-item,
.can-delete-media-item,
.can-approve-media-item,
.can-edit-media-item,
.can-edit-responsible,
.can-edit-licence-item,
.can-edit-matchcode {
  zoom: 1;
  display: none !important;
}
.can-edit-addresses:before,
.can-mass-upload:before,
.can-view-activitylog:before,
.can-view-statistic:before,
.can-send-newsletter:before,
.can-edit-user:before,
.can-edit-nav:before,
.can-edit-content:before,
.can-disable-media-item:before,
.can-delete-media-item:before,
.can-approve-media-item:before,
.can-edit-media-item:before,
.can-edit-responsible:before,
.can-edit-licence-item:before,
.can-edit-matchcode:before,
.can-edit-addresses:after,
.can-mass-upload:after,
.can-view-activitylog:after,
.can-view-statistic:after,
.can-send-newsletter:after,
.can-edit-user:after,
.can-edit-nav:after,
.can-edit-content:after,
.can-disable-media-item:after,
.can-delete-media-item:after,
.can-approve-media-item:after,
.can-edit-media-item:after,
.can-edit-responsible:after,
.can-edit-licence-item:after,
.can-edit-matchcode:after {
  content: "";
  display: table;
}
.can-edit-addresses:after,
.can-mass-upload:after,
.can-view-activitylog:after,
.can-view-statistic:after,
.can-send-newsletter:after,
.can-edit-user:after,
.can-edit-nav:after,
.can-edit-content:after,
.can-disable-media-item:after,
.can-delete-media-item:after,
.can-approve-media-item:after,
.can-edit-media-item:after,
.can-edit-responsible:after,
.can-edit-licence-item:after,
.can-edit-matchcode:after {
  clear: both;
}
body[data-can-disable-media-item="true"] .can-disable-media-item {
  display: inline-block !important;
}
body[data-can-edit-addresses="true"] .can-edit-addresses {
  display: block !important;
}
body[data-can-mass-upload="true"] .can-mass-upload {
  display: block !important;
}
body[data-can-view-activitylog="true"] .can-view-activitylog {
  display: block !important;
}
body[data-can-view-statistic="true"] .can-view-statistic {
  display: block !important;
}
body[data-can-edit-media-item="true"] .can-edit-media-item {
  display: table-cell !important;
  padding-right: 5px;
  vertical-align: middle;
}
body[data-can-edit-media-item="true"] .can-edit-media-item + span {
  display: table-cell;
  vertical-align: middle;
}
body[data-can-edit-responsible="true"] .can-edit-responsible {
  display: table-cell !important;
  padding-right: 5px;
  vertical-align: middle;
}
body[data-can-edit-responsible="true"] .can-edit-responsible + span {
  display: table-cell;
  vertical-align: middle;
}
body[data-can-edit-licence-item="true"] .can-edit-licence-item {
  display: table-cell !important;
  padding-right: 5px;
  vertical-align: middle;
}
body[data-can-edit-licence-item="true"] .can-edit-licence-item + span {
  display: table-cell;
  vertical-align: middle;
}
body[data-can-send-newsletter="true"] .can-send-newsletter {
  display: block !important;
}
body[data-can-edit-user="true"] .can-edit-user {
  display: block !important;
}
body[data-can-edit-nav="true"] .can-edit-nav {
  display: block !important;
}
body[data-can-edit-content="true"] .can-edit-content {
  display: inline-block !important;
}
body[data-can-delete-media-item="true"] .can-delete-media-item {
  display: block !important;
}
body[data-can-approve-media-item="true"] .can-approve-media-item {
  display: block !important;
}
body[data-can-edit-matchcode="true"] .can-edit-matchcode {
  display: block !important;
}
#DivMain #DivMainCenterWrapper .ContextModul .horizontal-radio-list {
  zoom: 1;
  height: 50px;
}
#DivMain #DivMainCenterWrapper .ContextModul .horizontal-radio-list:before,
#DivMain #DivMainCenterWrapper .ContextModul .horizontal-radio-list:after {
  content: "";
  display: table;
}
#DivMain #DivMainCenterWrapper .ContextModul .horizontal-radio-list:after {
  clear: both;
}
#DivMain #DivMainCenterWrapper .ContextModul .horizontal-radio-list label {
  zoom: 1;
  display: inline-block;
  float: left;
  margin-top: 12px;
}
#DivMain #DivMainCenterWrapper .ContextModul .horizontal-radio-list label:before,
#DivMain #DivMainCenterWrapper .ContextModul .horizontal-radio-list label:after {
  content: "";
  display: table;
}
#DivMain #DivMainCenterWrapper .ContextModul .horizontal-radio-list label:after {
  clear: both;
}
#DivMain #DivMainCenterWrapper .ContextModul .horizontal-radio-list label input {
  float: left;
}
#DivMain #DivMainCenterWrapper .ContextModul .horizontal-radio-list label span {
  float: left;
  margin-top: 2px;
  margin-right: 10px;
}
#DivMain #DivMainCenterWrapper .ContextModul .upload-wrapper {
  display: none;
}
#DivMain #DivMainCenterWrapper .ContextModul .upload-wrapper input[type=text] {
  width: 223px;
}
#DivMain #DivMainCenterWrapper .ContextModul .upload-wrapper textarea {
  width: 365px;
  min-width: 365px;
  max-width: 365px;
  height: 135px;
  min-height: 135px;
}
#DivMain #DivMainCenterWrapper .ContextModul .upload-wrapper textarea.notice {
  height: 75px;
  min-height: 75px;
}
#DivMain #DivMainCenterWrapper .ContextModul .upload-wrapper td.top {
  vertical-align: top !important;
  padding-top: 5px;
}
#DivMain #DivMainCenterWrapper .ContextModul .upload-wrapper .k-datepicker {
  width: 228px;
}
#DivMain #DivMainCenterWrapper .ContextModul .upload-wrapper .k-textbox {
  width: 223px;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  border-radius: 0;
  height: 16px;
}
#DivMain #DivMainCenterWrapper .ContextModul .upload-wrapper .k-dropdown,
#DivMain #DivMainCenterWrapper .ContextModul .upload-wrapper .k-autocomplete {
  width: 229px;
}
#DivMain #DivMainCenterWrapper .ContextModul .upload-wrapper .k-multiselect {
  width: 100%;
  border-width: 1px;
  border-style: solid;
}
#DivMain #DivMainCenterWrapper .ContextModul .upload-wrapper .k-multiselect .k-multiselect-wrap {
  padding: 5px 5px 5px 5px;
}
#DivMain #DivMainCenterWrapper .ContextModul .upload-wrapper .copyright {
  zoom: 1;
  padding-bottom: 10px;
}
#DivMain #DivMainCenterWrapper .ContextModul .upload-wrapper .copyright:before,
#DivMain #DivMainCenterWrapper .ContextModul .upload-wrapper .copyright:after {
  content: "";
  display: table;
}
#DivMain #DivMainCenterWrapper .ContextModul .upload-wrapper .copyright:after {
  clear: both;
}
#DivMain #DivMainCenterWrapper .ContextModul .upload-wrapper .copyright > div {
  float: left;
}
#DivMain #DivMainCenterWrapper .ContextModul .upload-wrapper .copyright > div:first-child {
  width: 200px;
}
#DivMain #DivMainCenterWrapper .ContextModul .upload-wrapper .k-autocomplete,
#DivMain #DivMainCenterWrapper .ContextModul .upload-wrapper .k-listview {
  border-color: #b2b2b2;
  border-radius: 0;
}
#DivMain #DivMainCenterWrapper .ContextModul .upload-wrapper .k-listview {
  height: 138px;
  margin-bottom: 10px;
  width: 228px;
  overflow: auto;
}
#DivMain #DivMainCenterWrapper .ContextModul .upload-wrapper .k-listview div {
  padding: 3px 5px;
}
#DivMain #DivMainCenterWrapper .ContextModul .upload-wrapper .k-listview div.k-state-selected {
  background-color: #7cb83f;
  background-image: none;
}
#DivMain #DivMainCenterWrapper .ContextModul .upload-wrapper .UploadedVideos,
#DivMain #DivMainCenterWrapper .ContextModul .upload-wrapper #YoutubeCulturesGrid {
  height: 114px;
  margin-top: 5px;
  overflow: auto;
}
#DivMain #DivMainCenterWrapper .ContextModul .upload-wrapper .video-information-block .selection-video-type-wrapper p {
  float: left;
}
#DivMain #DivMainCenterWrapper .ContextModul .upload-wrapper .video-information-block .selection-video-type-wrapper .selection-video-type {
  float: right;
  margin-top: 10px;
  margin-right: 155px;
}
#DivMain #DivMainCenterWrapper .ContextModul .upload-wrapper .video-information-block .selection-video-type-wrapper .selection-video-type .radio {
  margin-left: 15px;
  display: block;
  float: left;
}
#DivMain #DivMainCenterWrapper .ContextModul .upload-wrapper .video-information-block .selection-video-type-wrapper .selection-video-type .radio input {
  float: left;
}
#DivMain #DivMainCenterWrapper .ContextModul .upload-wrapper .video-information-block .selection-video-type-wrapper .selection-video-type .radio span {
  float: left;
  margin-top: 2px;
  margin-left: 5px;
  display: block;
}
#DivMain #DivMainCenterWrapper .ContextModul .upload-wrapper .pdf-information-block .selection-pdf-type-wrapper p {
  float: left;
}
#DivMain #DivMainCenterWrapper .ContextModul .upload-wrapper .pdf-information-block .selection-pdf-type-wrapper .selection-pdf-type {
  float: right;
  margin-top: 10px;
  margin-right: 155px;
}
#DivMain #DivMainCenterWrapper .ContextModul .upload-wrapper .pdf-information-block .selection-pdf-type-wrapper .selection-pdf-type .radio {
  margin-left: 15px;
  display: block;
  float: left;
}
#DivMain #DivMainCenterWrapper .ContextModul .upload-wrapper .pdf-information-block .selection-pdf-type-wrapper .selection-pdf-type .radio input {
  float: left;
}
#DivMain #DivMainCenterWrapper .ContextModul .upload-wrapper .pdf-information-block .selection-pdf-type-wrapper .selection-pdf-type .radio span {
  float: left;
  margin-top: 2px;
  margin-left: 5px;
  display: block;
}
#DivMain #DivMainCenterWrapper .ContextModul .upload-wrapper .error-youtube {
  text-align: left;
  display: none;
}
#DivMain #DivMainCenterWrapper .ContextModul .upload-wrapper .keyword-wrapper .selected-wrapper {
  height: 114px;
  width: 227px;
  overflow: auto;
}
#DivMain #DivMainCenterWrapper .ContextModul .upload-wrapper .keyword-wrapper .k-autocomplete {
  height: 22px;
}
#DivMain #DivMainCenterWrapper .ContextModul .upload-wrapper .keyword-wrapper .k-autocomplete input {
  height: 16px;
}
#DivMain #DivMainCenterWrapper .ContextModul .upload-wrapper .keyword-wrapper .cell-left {
  padding-bottom: 15px;
  color: #1e1e1e;
}
#DivMain #DivMainCenterWrapper .ContextModul .upload-wrapper .keyword-wrapper .cell-left:first-child {
  padding-bottom: 1px;
}
#DivMain #DivMainCenterWrapper .ContextModul .upload-wrapper .keyword-wrapper .btnDeleteSearchKeyWords {
  display: none;
}
#DivMain #DivMainCenterWrapper .ContextModul .upload-wrapper .desc-wrapper {
  zoom: 1;
}
#DivMain #DivMainCenterWrapper .ContextModul .upload-wrapper .desc-wrapper:before,
#DivMain #DivMainCenterWrapper .ContextModul .upload-wrapper .desc-wrapper:after {
  content: "";
  display: table;
}
#DivMain #DivMainCenterWrapper .ContextModul .upload-wrapper .desc-wrapper:after {
  clear: both;
}
#DivMain #DivMainCenterWrapper .ContextModul .upload-wrapper .desc-wrapper > div {
  float: left;
}
#DivMain #DivMainCenterWrapper .ContextModul .upload-wrapper .desc-wrapper > div:first-child {
  margin-right: 15px;
}
#DivMain #DivMainCenterWrapper .ContextModul .upload-wrapper .desc-wrapper > div textarea {
  min-width: 248px;
  max-width: 248px;
  width: 248px;
}
#DivMain #DivMainCenterWrapper .ContextModul .table-pictures {
  width: 100%;
}
#DivMain #DivMainCenterWrapper .ContextModul .table-pictures tr td {
  vertical-align: top;
}
#DivMain #DivMainCenterWrapper .ContextModul .table-pictures tr td.cell-left {
  width: 130px;
  padding-right: 6px;
  vertical-align: middle;
}
#DivMain #DivMainCenterWrapper .ContextModul .table-pictures tr td.cell-left-cb {
  width: 170px;
  padding-right: 6px;
  vertical-align: top;
}
#DivMain #DivMainCenterWrapper .ContextModul .table-pictures tr td.cell-align-top {
  padding-top: 5px;
  vertical-align: top;
}
#DivMain #DivMainCenterWrapper .ContextModul .table-pictures tr td select {
  width: 150px;
}
#DivMain #DivMainCenterWrapper .ContextModul .table-pictures tr td .search-box {
  float: left;
  margin-right: 10px;
}
#DivMain #DivMainCenterWrapper .ContextModul .table-videos {
  width: 100%;
}
#DivMain #DivMainCenterWrapper .ContextModul .table-videos tr td {
  vertical-align: top;
}
#DivMain #DivMainCenterWrapper .ContextModul .table-videos tr td.cell-left {
  width: 130px;
  padding-right: 6px;
  vertical-align: middle;
}
#DivMain #DivMainCenterWrapper .ContextModul .table-videos tr td.cell-align-top {
  padding-top: 5px;
  vertical-align: top;
}
#DivMain #DivMainCenterWrapper .ContextModul .table-videos tr td.cell-left-cb {
  width: 170px;
  padding-right: 6px;
  vertical-align: top;
}
#DivMain #DivMainCenterWrapper .ContextModul .table-videos tr td select {
  width: 150px;
}
#DivMain #DivMainCenterWrapper .ContextModul .table-videos tr td .search-box {
  float: left;
  margin-right: 10px;
}
#DivMain #DivMainCenterWrapper .ContextModul .table-pdfs {
  width: 100%;
}
#DivMain #DivMainCenterWrapper .ContextModul .table-pdfs tr td {
  vertical-align: top;
}
#DivMain #DivMainCenterWrapper .ContextModul .table-pdfs tr td.cell-left {
  width: 130px;
  padding-right: 6px;
  vertical-align: middle;
}
#DivMain #DivMainCenterWrapper .ContextModul .table-pdfs tr td.cell-align-top {
  padding-top: 5px;
  vertical-align: top;
}
#DivMain #DivMainCenterWrapper .ContextModul .table-pdfs tr td.cell-left-cb {
  width: 170px;
  padding-right: 6px;
  vertical-align: top;
}
#DivMain #DivMainCenterWrapper .ContextModul .table-pdfs tr td select {
  width: 150px;
}
#DivMain #DivMainCenterWrapper .ContextModul .table-pdfs tr td .search-box {
  float: left;
  margin-right: 10px;
}
#DivMain #DivMainCenterWrapper .ContextModul .table-openfiles {
  width: 100%;
}
#DivMain #DivMainCenterWrapper .ContextModul .table-openfiles tr td {
  vertical-align: top;
}
#DivMain #DivMainCenterWrapper .ContextModul .table-openfiles tr td.cell-left {
  width: 130px;
  padding-right: 6px;
  vertical-align: middle;
}
#DivMain #DivMainCenterWrapper .ContextModul .table-openfiles tr td.cell-align-top {
  padding-top: 5px;
  vertical-align: top;
}
#DivMain #DivMainCenterWrapper .ContextModul .table-openfiles tr td.cell-left-cb {
  width: 170px;
  padding-right: 6px;
  vertical-align: top;
}
#DivMain #DivMainCenterWrapper .ContextModul .table-openfiles tr td select {
  width: 150px;
}
#DivMain #DivMainCenterWrapper .ContextModul .table-openfiles tr td .search-box {
  float: left;
  margin-right: 10px;
}
#DivMain #DivMainCenterWrapper .ContextModul .table-illustrations {
  width: 100%;
}
#DivMain #DivMainCenterWrapper .ContextModul .table-illustrations tr td {
  vertical-align: top;
}
#DivMain #DivMainCenterWrapper .ContextModul .table-illustrations tr td.cell-left {
  width: 130px;
  padding-right: 6px;
  vertical-align: middle;
}
#DivMain #DivMainCenterWrapper .ContextModul .table-illustrations tr td.cell-align-top {
  padding-top: 5px;
  vertical-align: top;
}
#DivMain #DivMainCenterWrapper .ContextModul .table-illustrations tr td.cell-left-cb {
  width: 170px;
  padding-right: 6px;
  vertical-align: top;
}
#DivMain #DivMainCenterWrapper .ContextModul .table-illustrations tr td select {
  width: 150px;
}
#DivMain #DivMainCenterWrapper .ContextModul .table-illustrations tr td .search-box {
  float: left;
  margin-right: 10px;
}
#DivMain #DivMainCenterWrapper .ContextModul .tdHeadline {
  margin-left: -3px;
}
#DivWrapper #DivMain .keyword-wrapper .selected-wrapper {
  /*height: 100px;*/
  width: 313px;
  /*overflow: auto;*/
  margin-bottom: 10px;
}
#DivWrapper #DivMain .keyword-wrapper .selected-wrapper div {
  /*padding: 3px 5px;*/
}
#DivWrapper #DivMain .keyword-wrapper .selected-wrapper div.k-state-selected {
  background-color: #7cb83f;
  background-image: none;
}
#DivWrapper #DivMain .keyword-wrapper .selected-wrapper td {
  width: auto;
}
.image-nav-selector {
  zoom: 1;
}
.image-nav-selector:before,
.image-nav-selector:after {
  content: "";
  display: table;
}
.image-nav-selector:after {
  clear: both;
}
.image-nav-selector .selectoors-wrapper {
  float: left;
  width: 130px;
  margin-right: 12px;
}
.image-nav-selector .selectoors-wrapper .k-dropdown {
  width: 100% !important;
  margin-bottom: 10px;
}
.image-nav-selector .selected-wrapper {
  float: left;
  width: 230px;
}
.image-nav-selector .selected-wrapper .selected-nav {
  height: 138px;
  margin-bottom: 10px;
  width: 228px;
  overflow: auto;
}
.image-nav-selector .selected-wrapper .selected-nav div {
  padding: 3px 5px;
}
.image-nav-selector .selected-wrapper .selected-nav div.k-state-selected {
  background-color: #7cb83f;
  background-image: none;
}
.openfile-nav-selector {
  zoom: 1;
}
.openfile-nav-selector:before,
.openfile-nav-selector:after {
  content: "";
  display: table;
}
.openfile-nav-selector:after {
  clear: both;
}
.openfile-nav-selector .openfile-selectors-wrapper {
  float: left;
  width: 130px;
  margin-right: 12px;
}
.openfile-nav-selector .openfile-selectors-wrapper .k-dropdown {
  width: 100% !important;
  margin-bottom: 10px;
}
.openfile-nav-selector .openfile-selected-wrapper {
  float: left;
  width: 230px;
}
.openfile-nav-selector .openfile-selected-wrapper .openfile-selected-nav {
  height: 138px;
  margin-bottom: 10px;
  width: 228px;
  overflow: auto;
}
.openfile-nav-selector .openfile-selected-wrapper .openfile-selected-nav div {
  padding: 3px 5px;
}
.openfile-nav-selector .openfile-selected-wrapper .openfile-selected-nav div.k-state-selected {
  background-color: #7cb83f;
  background-image: none;
}
.audiovideo-nav-selector {
  zoom: 1;
}
.audiovideo-nav-selector:before,
.audiovideo-nav-selector:after {
  content: "";
  display: table;
}
.audiovideo-nav-selector:after {
  clear: both;
}
.audiovideo-nav-selector .audiovideo-selectors-wrapper {
  float: left;
  width: 130px;
  margin-right: 12px;
}
.audiovideo-nav-selector .audiovideo-selectors-wrapper .k-dropdown {
  width: 100% !important;
  margin-bottom: 10px;
}
.audiovideo-nav-selector .audiovideo-selected-wrapper {
  float: left;
  width: 230px;
}
.audiovideo-nav-selector .audiovideo-selected-wrapper .audiovideo-selected-nav {
  height: 138px;
  margin-bottom: 10px;
  width: 228px;
  overflow: auto;
}
.audiovideo-nav-selector .audiovideo-selected-wrapper .audiovideo-selected-nav div {
  padding: 3px 5px;
}
.audiovideo-nav-selector .audiovideo-selected-wrapper .audiovideo-selected-nav div.k-state-selected {
  background-color: #7cb83f;
  background-image: none;
}
.pdf-nav-selector {
  zoom: 1;
}
.pdf-nav-selector:before,
.pdf-nav-selector:after {
  content: "";
  display: table;
}
.pdf-nav-selector:after {
  clear: both;
}
.pdf-nav-selector .pdf-selectors-wrapper {
  float: left;
  width: 130px;
  margin-right: 12px;
}
.pdf-nav-selector .pdf-selectors-wrapper .k-dropdown {
  width: 100% !important;
  margin-bottom: 10px;
}
.pdf-nav-selector .pdf-selected-wrapper {
  float: left;
  width: 230px;
}
.pdf-nav-selector .pdf-selected-wrapper .selected-nav {
  height: 138px;
  margin-bottom: 10px;
  width: 228px;
  overflow: auto;
}
.pdf-nav-selector .pdf-selected-wrapper .selected-nav div {
  padding: 3px 5px;
}
.pdf-nav-selector .pdf-selected-wrapper .selected-nav div.k-state-selected {
  background-color: #7cb83f;
  background-image: none;
}
.restriction-nav-selector {
  zoom: 1;
}
.restriction-nav-selector:before,
.restriction-nav-selector:after {
  content: "";
  display: table;
}
.restriction-nav-selector:after {
  clear: both;
}
.restriction-nav-selector .restriction-selectors-wrapper {
  float: left;
  width: 130px;
  margin-right: 12px;
}
.restriction-nav-selector .restriction-selectors-wrapper .k-dropdown {
  width: 100% !important;
  margin-bottom: 10px;
}
.restriction-nav-selector .restriction-selected-wrapper {
  float: left;
  width: 230px;
}
.restriction-nav-selector .restriction-selected-wrapper .restriction-selected-nav {
  height: 138px;
  margin-bottom: 10px;
  width: 228px;
  overflow: auto;
}
.restriction-nav-selector .restriction-selected-wrapper .restriction-selected-nav div {
  padding: 3px 5px;
}
.restriction-nav-selector .restriction-selected-wrapper .restriction-selected-nav div.k-state-selected {
  background-color: #7cb83f;
  background-image: none;
}
.illustration-nav-selector {
  zoom: 1;
}
.illustration-nav-selector:before,
.illustration-nav-selector:after {
  content: "";
  display: table;
}
.illustration-nav-selector:after {
  clear: both;
}
.illustration-nav-selector .illustration-selectors-wrapper {
  float: left;
  width: 130px;
  margin-right: 12px;
}
.illustration-nav-selector .illustration-selectors-wrapper .k-dropdown {
  width: 100% !important;
  margin-bottom: 10px;
}
.illustration-nav-selector .illustration-selected-wrapper {
  float: left;
  width: 230px;
}
.illustration-nav-selector .illustration-selected-wrapper .illustration-selected-nav {
  height: 138px;
  margin-bottom: 10px;
  width: 228px;
  overflow: auto;
}
.illustration-nav-selector .illustration-selected-wrapper .illustration-selected-nav div {
  padding: 3px 5px;
}
.illustration-nav-selector .illustration-selected-wrapper .illustration-selected-nav div.k-state-selected {
  background-color: #7cb83f;
  background-image: none;
}
.file-upload-wrapper > input {
  width: 224px;
  float: left;
  display: block;
}
.file-upload-wrapper a.link-button {
  float: left;
  margin-left: 10px;
  display: block;
}
.file-upload-wrapper .k-upload {
  /*display: none;*/
  border-radius: 0;
  border: none;
  float: left;
  margin: 10px 0;
  width: 100%;
}
.file-upload-wrapper .k-upload .k-dropzone {
  display: none;
}
.file-upload-wrapper .k-upload .k-upload-files {
  border: 1px solid #CECED2;
  margin: 0;
}
.file-upload-wrapper .k-upload .k-upload-files .k-file-success,
.file-upload-wrapper .k-upload .k-upload-files .k-file-progress {
  border: none;
  color: #333;
}
.file-upload-wrapper .k-upload .k-upload-files .k-file-success .k-progress,
.file-upload-wrapper .k-upload .k-upload-files .k-file-progress .k-progress {
  background: #21a0d2;
}
.file-upload-wrapper .k-upload .k-upload-files .k-file-success .k-file-extension-wrapper,
.file-upload-wrapper .k-upload .k-upload-files .k-file-progress .k-file-extension-wrapper {
  display: none;
}
.file-upload-wrapper .k-upload .k-upload-files .k-file-success .k-file-name-size-wrapper,
.file-upload-wrapper .k-upload .k-upload-files .k-file-progress .k-file-name-size-wrapper {
  margin: 0;
}
.file-upload-wrapper .k-upload .k-upload-files .k-file-success .k-file-name-size-wrapper .k-file-name,
.file-upload-wrapper .k-upload .k-upload-files .k-file-progress .k-file-name-size-wrapper .k-file-name {
  margin-right: 10px;
}
.file-upload-wrapper .k-upload .k-upload-files .k-file-success .k-file-name {
  color: #65ac1e;
}
.nav-selector tr {
  height: 30px;
}
.nav-selector tr td {
  font-size: 12px;
}
.nav-selector tr td:first-child {
  width: 130px;
  font-weight: bold;
  padding-right: 6px;
}
.nav-selector tr td .k-dropdown {
  margin: 0;
}
.upload-state {
  width: 300px;
}
.upload-state .loader {
  height: 53px;
  width: 50px;
  background: url(/App_Images/loader.gif) no-repeat;
  display: block;
  margin-left: 115px;
  padding-bottom: 10px;
}
.loader-area {
  position: absolute;
}
.loading {
  display: none;
  left: 0;
  top: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url(/App_Images/Tools/modal.png);
  z-index: 10000;
}
.loading .loader {
  height: 53px;
  width: 50px;
  background-image: url(/App_Images/Tools/loader.gif);
  position: relative;
  top: 50%;
  left: 50%;
  margin-left: -25px;
  margin-top: -26px;
}
#DivMain #DivMainCenterWrapper #ForgotPassword.ContextModul .Content {
  height: auto;
  position: static;
}
#DivMain #DivMainCenterWrapper #ForgotPassword.ContextModul .Content .ContentInner .k-invalid-msg {
  position: relative;
  padding-left: 0px;
  text-align: left;
}
#DivMain #DivMainCenterWrapper #ForgotPassword.ContextModul .Content .ContentInner .k-invalid-msg.compare-password {
  margin-left: 0px;
}
#DivMain #DivMainCenterWrapper #ForgotPassword.ContextModul .Content .ContentInner label.intern,
#DivMain #DivMainCenterWrapper #ForgotPassword.ContextModul .Content .ContentInner label.extern {
  display: block;
  float: left;
  padding-top: 2px;
  width: 40px;
}
#DivMain #DivMainCenterWrapper #ForgotPassword.ContextModul .Content .ContentInner .FormRow {
  zoom: 1;
  margin-top: 5px;
  margin-bottom: 5px;
  width: 500px;
}
#DivMain #DivMainCenterWrapper #ForgotPassword.ContextModul .Content .ContentInner .FormRow:before,
#DivMain #DivMainCenterWrapper #ForgotPassword.ContextModul .Content .ContentInner .FormRow:after {
  content: "";
  display: table;
}
#DivMain #DivMainCenterWrapper #ForgotPassword.ContextModul .Content .ContentInner .FormRow:after {
  clear: both;
}
#DivMain #DivMainCenterWrapper #ForgotPassword.ContextModul .Content .ContentInner .FormRow input {
  padding: 2px;
}
#DivMain #DivMainCenterWrapper #ForgotPassword.ContextModul .Content .ContentInner .FormRow .label {
  display: block;
  float: left;
  min-width: 125px;
  padding-top: 5px;
  padding-right: 10px;
}
#DivMain #DivMainCenterWrapper #ForgotPassword.ContextModul .Content .ContentInner .FormRow .pflicht {
  display: block;
  text-align: right;
  margin-right: 10px;
}
#DivMain #DivMainCenterWrapper #ForgotPassword.ContextModul .Content .ContentInner .FormRow .dropdown {
  float: left;
  width: 100px;
}
#DivMain #DivMainCenterWrapper #ForgotPassword.ContextModul .Content .ContentInner .FormRow .textbox {
  margin-right: 5px;
  width: 230px;
}
#DivMain #DivMainCenterWrapper #ForgotPassword.ContextModul .Content .ContentInner .FormPrivacy {
  margin-top: 5px;
  margin-bottom: 5px;
}
#DivMain #DivMainCenterWrapper #ForgotPassword.ContextModul .Content .ContentInner .FormPrivacy .k-invalid-msg {
  background-color: #fff;
  border: none;
  box-shadow: none;
  position: absolute;
  margin-top: 20px !important;
  left: 198px;
}
#DivMain #DivMainCenterWrapper #ForgotPassword.ContextModul .Content .ContentInner .FormPrivacy .k-invalid-msg .k-warning {
  display: none;
}
#DivMain #DivMainCenterWrapper #ForgotPassword.ContextModul .Content .ContentInner .FormInfo {
  margin-top: 15px;
}
#DivMain #DivMainCenterWrapper #ForgotPassword.ContextModul .Content .ContentInner .FormButton {
  margin-top: 30px;
}
.matchcode-creation-wrapper .matchcode-creation-header .row-item {
  zoom: 1;
  margin-bottom: 5px;
}
.matchcode-creation-wrapper .matchcode-creation-header .row-item:before,
.matchcode-creation-wrapper .matchcode-creation-header .row-item:after {
  content: "";
  display: table;
}
.matchcode-creation-wrapper .matchcode-creation-header .row-item:after {
  clear: both;
}
.matchcode-creation-wrapper .matchcode-creation-header .row-item.languagefilter {
  margin-bottom: 10px;
}
.matchcode-creation-wrapper .matchcode-creation-header .row-item .left {
  float: left;
}
.matchcode-creation-wrapper .matchcode-creation-header .row-item .left .matchcode-filter,
.matchcode-creation-wrapper .matchcode-creation-header .row-item .left .matchcode-language,
.matchcode-creation-wrapper .matchcode-creation-header .row-item .left .matchcode-searchkey {
  display: inline-block;
  width: 100px;
}
.matchcode-creation-wrapper .matchcode-creation-header .row-item .right {
  float: right;
  margin-right: 8px;
}
.matchcode-creation-wrapper .matchcode-creation-header .row-item #MatchCodeSearchBox {
  width: 143px;
}
.matchcode-creation-wrapper .matchcode-creation-main {
  margin-top: 10px;
  margin-bottom: 10px;
}
.matchcode-creation-wrapper .matchcode-creation-main .character-selector {
  margin-left: -5px;
  margin-bottom: 5px;
}
.matchcode-creation-wrapper .matchcode-creation-main .character-selector a {
  display: inline-block;
  width: 30px;
  height: 30px;
  background-color: #f1f1f1;
  line-height: 30px;
  text-align: center;
  margin: 5px;
  cursor: pointer;
}
.matchcode-creation-wrapper .matchcode-creation-main .character-selector a:hover,
.matchcode-creation-wrapper .matchcode-creation-main .character-selector a.selected {
  background-color: #7cb83f;
  background-image: none;
  color: #fff;
}
.matchcode-creation-wrapper .matchcode-creation-main .matchcode-keyword-grid,
.matchcode-creation-wrapper .matchcode-creation-main .matchcode-inactive-keyword-grid,
.matchcode-creation-wrapper .matchcode-creation-main .matchcode-suggestion-grid {
  display: none;
  margin-right: 15px;
}
.matchcode-creation-wrapper .matchcode-creation-main tbody tr td {
  border: none;
  font-size: 12px;
  background-color: #fff;
  border-bottom: 1px solid #f1f1f1;
  vertical-align: middle;
  height: 32px;
  padding-top: 1px;
  padding-bottom: 0;
}
.matchcode-creation-wrapper .matchcode-creation-main tbody tr td.col-name {
  color: #004a96;
}
.matchcode-creation-wrapper .matchcode-creation-main tbody tr td.col-cmds {
  text-align: right;
  padding: 0;
}
.matchcode-creation-wrapper .matchcode-creation-main tbody tr td.col-cmds .k-button {
  width: 20px;
  height: 20px;
  border: none;
  min-width: 0;
  border-radius: 0;
  color: transparent;
  text-indent: -10000%;
}
.matchcode-creation-wrapper .matchcode-creation-main tbody tr td.col-cmds .k-button span {
  display: none;
}
.matchcode-creation-wrapper .matchcode-creation-main tbody tr td.col-cmds .k-button.Activate {
  background: url("/App_Images/MediaList/icon_einblenden.png") no-repeat;
}
.matchcode-creation-wrapper .matchcode-creation-main tbody tr td.col-cmds .k-button.Delete {
  background: url(/App_Images/buttons/icon-delete.png) no-repeat;
}
.matchcode-creation-wrapper .matchcode-creation-main tbody tr td.col-cmds .k-button.ToggleState {
  background: url("/App_Images/MediaList/icon_ausblenden.png") no-repeat;
}
.matchcode-creation-wrapper .matchcode-creation-main tbody tr td.col-cmds .k-button.ToggleState.isHidden {
  background: url("/App_Images/MediaList/icon_einblenden.png") no-repeat;
}
.matchcode-creation-wrapper .matchcode-creation-main tbody tr td.col-cmds .k-grid-edit {
  background: url(/App_Images/buttons/icon-edit.png) no-repeat;
}
.matchcode-creation-wrapper .matchcode-creation-main tbody tr td.col-cmds .k-grid-delete {
  background: url(/App_Images/buttons/icon-delete.png) no-repeat;
}
.matchcode-creation-wrapper .matchcode-creation-main tbody tr td.col-cmds .k-grid-update {
  background: url(/App_Images/buttons/icon-save_green.png) no-repeat;
}
.matchcode-creation-wrapper .matchcode-creation-main tbody tr td.col-cmds .k-grid-cancel {
  background: url(/App_Images/buttons/icon-abbort_grey.png) no-repeat;
}
.matchcode-creation-wrapper .matchcode-creation-main tbody tr td input.k-input {
  border-radius: 0;
  border: 1px solid #004a96;
  color: #000;
}
#AddressManagement #AddressManagementDeputyGrid tr td {
  font-size: 12px;
}
